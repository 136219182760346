<template>
  <div class="expandable-transaction-check-list">
    <div class="transaction-list-description">
      <div class="title-with-expand-collapse" v-on:click="toggleExpand">
        <div v-if="title" class="title" :class="[colorVariant]">{{ title }}</div>
        <div v-if="isExpandable" class="image-container">
          <img v-if="isExpanded" src="/images/onboarding/icons/ic-chevron-up-black.svg">
          <img v-else src="/images/onboarding/icons/ic-chevron-down-black.svg">
        </div>
      </div>
      <slot name="subtitle" v-if="(isExpanded || !isExpandable)"/>
    </div>
    <separator v-if="transactionList" />
    <transaction-check-list v-if="transactionList && (isExpanded || !isExpandable)" :transaction-list="transactionList" :toggleCheck="toggleCheck"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import TransactionCheckList from './TransactionCheckList.vue';

export default {
  name: 'ExpandableTransactionCheckList',
  components: {
    TransactionCheckList,
    Separator: BaseUI.Separator,
  },
  props: {
    transactionList: {
      type: Array,
      required: false,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
    isExpandable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    colorVariant: {
      type: String,
      validator: val => ['green', 'red'].includes(val),
    },
    expandOnCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  created() {
    if (this.expandOnCreate) {
      this.isExpanded = true;
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.expandable-transaction-check-list {
  width: 100%;
  .transaction-list-description {
    @include responsive-padding;

    .title-with-expand-collapse {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 25px;
        &.green {
          color: $riseup_green;
        }
        &.red {
          color: $riseup_red;
        }
      }

      .image-container {
        height: 100%;
        margin-bottom: 25px;
      }
    }
  }
}
</style>
