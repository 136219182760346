<template>
  <div class="action">
    <slot/>
    <div class="label" :class="{ disabled }">{{ label }}</div>
  </div>

</template>

<script>
export default {
  name: 'Action',
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.action {
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    color: $riseup-blue;

    &.disabled {
      color: $riseup_gray_2;
    }
  }
}

</style>
