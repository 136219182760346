<template>
<card class="testimonial">
    <div class="header">
      <img :src="`/images/onboarding/testimonials/${image}`" alt="avatar" class="testimonial-image" />
      <span class="ri-bold-body">{{personName}}</span>
    </div>
    <div>
      <span class="ri-body">{{testimonial}}</span>
    </div>
</card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'Testimonial',
  components: {
    card: BaseUI.Card,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    personName: {
      type: String,
      required: true,
    },
    testimonial: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.testimonial {
  width: 287px;
  height: 216px;
  background-color: $riseup_white;
  padding: $ri-spacing-ml 0;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: $ri-spacing-ml;
    gap: $ri-spacing-sm;

    .testimonial-image {
      height: 48px;
      width: 48px;
    }
  }
}
</style>
