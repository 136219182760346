<template>
  <connection-failure-base class="scrape-failure" :close-action="closeAction" :source="source" :buttons="buttons">
    <template v-slot:title>
      {{title}}
    </template>
    <template v-slot:subtitle>
    <span class="bold">כדאי לנסות שוב</span>
        <span>או לחבר את החשבון עם שם המשתמש והסיסמה שלך ל{{sourceName}}</span>
    </template>
  </connection-failure-base>
</template>

<script>
import CreateCredentialsFlow
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CreateCredentialsFlow';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import ConnectionFailureBase from './ConnectionFailureBase';

export default {
  name: 'FirstScrapeFailure',
  components: {
    ConnectionFailureBase,
  },
  props: {
    sourceName: {
      type: String,
      require: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKScrapeFailureShown', { source: this.sourceName });
  },
  computed: {
    title() {
      return `נראה שהחיבור ל${this.sourceName} לא הצליח`;
    },
    buttons() {
      return [{
        title: 'לנסות שוב',
        action: this.tryAgain,
        size: 'slim',
      }, {
        variant: 'secondary',
        action: this.connectWithUsernameAndPassword,
        title: 'להתחבר עם פרטי ההתחברות',
        size: 'slim',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    connectWithUsernameAndPassword() {
      Segment.trackUserGot('OBKScrapeFailureShown_ConnectWithUsernameAndPasswordClicked', { source: this.sourceName });
      this.openModal({
        component: CreateCredentialsFlow,
        props: { preventOBkConnection: true },
        popupAlignment: 'full-screen',
      });
    },
    tryAgain() {
      Segment.trackUserGot('OBKScrapeFailureShown_TryAgainClicked', { source: this.sourceName });
      this.closeAction();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .scrape-failure {

    .bold {
      font-weight: bold;
    }
  }

</style>
