<template>
  <div class="sticky-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'StickyTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';
@import '../scss/global-variables';

.sticky-title {
  @include responsive-padding;
  position: sticky;
  top: $app_header_height;
  width: 100%;
  height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $riseup_white;
  border-bottom: solid 1px $riseup_gray_0;
  font-size: 16px;
  font-weight: bold;
  z-index: 10;
}
</style>
