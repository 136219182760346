<template>
  <div>
    <div class="accumulation-break-down ri-body">
      <div>
        לפי {{this.numberOfMonths}} החודש האחרונים, סך ההון שלי (בעו״ש ובחסכונות) גדל ב
        <span class="money"> ₪{{cashflowTotalAccumulatedSumFormatted}}</span>
      </div>
      <div class="margin-top-m">
        לפי {{this.numberOfMonths}} החודש האחרונים, העברתי
        <span class="savings">₪{{cashflowsTotalSavingAccumulation}}</span>
        לחסכונות (תזרימי ולא תזרימי)
      </div>
      <div class="margin-top-m">
        5 החוויות (ההוצאות הלא תזרימיות) הגדולות שלי:
        <div v-for="expense in top5ExcludedExpensses" :key="expense.transactionId">
          <span class="asset">💫 {{expense.businessName}}</span>
          <span v-if="expense.customerComment">{{expense.customerComment}}</span>
          <span>(ב₪{{expense.formattedBillingAmount}})</span>
        </div>
        <div class="ri-font-12">
          * אם יש כאן הפקדה לחיסכון, זה אומר שלא ספרנו אותה בחסכונות. כדי לשנות את זה, צריך להיכנס לתזרים ולסמן אותה כהוצאה לחיסכון שלא מהתזרים
        </div>
      </div>
      <div class="margin-top-xl">
        <text-input top-left-label="כמה חודשים אחורה להסתכל" :value="numberOfMonths" :on-input="setNumberOfMonths" :hint="oldestCashflow" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as moment from 'moment';
import BaseUI from '@riseupil/base-ui';

import { mapActions } from 'vuex';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import moneyUtils from '@/utils/money';
import { DateLocales } from '@riseupil/common-utils';

export default {
  name: 'RiseupPoolRaw',
  components: {
    TextInput: BaseUI.TextInput,
  },
  props: {
    riseupPool: {
      type: Array,
      required: true,
    },
    savingTransactionIdsFromHamster: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      numberOfMonths: '100',
    };
  },
  async created() {
    this.startContentLoading(this.$options.name);
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    last100months() {
      return this.lastNCashflowsBreakDown(Number(this.numberOfMonths));
    },
    cashflowTotalAccumulatedSumFormatted() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last100months, breakDown => this.totalAccumulation(breakDown)), 0,
      );
    },
    oldestCashflow() {
      const oldest = _.last(this.last100months)?.cashflowMonth;
      return `* התזרים הכי ישן שלך הוא מ${oldest}`;
    },
    cashflowsTotalAccumulatedSum() {
      return _.sumBy(this.last100months, breakDown => this.totalAccumulation(breakDown));
    },
    cashflowsTotalCashflowsAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last100months, breakDown => breakDown.cashflowBalance), 0,
        0,
      );
    },
    cashflowsTotalSavingAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last100months,
          accBreakDown => (accBreakDown.savingEnvelopesActualsSum
            + accBreakDown.oneTimeSavingActualSum
            + accBreakDown.expenseExclusionsThatAreNotSavings
            + accBreakDown.incomeExclusionsThatAreNotSavings)),
        0,
      );
    },
    cashflowsWithdrawnFromAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last100months,
          accBreakDown => accBreakDown.expenseExclusionsThatAreNotSavings),
        0,
      );
    },
    incomeExclusionsIntoIntoAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last100months,
          accBreakDown => accBreakDown.incomeExclusionsThatAreNotSavings),
        0,
      );
    },
    top5ExcludedExpensses() {
      const flatten = _.chain(this.last100months)
        .map(accBreakDown => accBreakDown.excludedNonSavingsExpenses)
        .flatten()
        .filter(ex => !ex.excludedFromPool)
        .orderBy(ex => ex.billingAmount, 'desc')
        .take(5)
        .map(item => { return { ...item, formattedBillingAmount: moneyUtils.formatAmountWithFraction(item.billingAmount, 0) }; })
        .value();
      return flatten;
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    lastNCashflowsBreakDown(n, exculdedTransactions) {
      const _formatMonthName = cashflowMonth => moment(cashflowMonth)
        .locale('he')
        .format('MMM');

      return _.chain(this.riseupPool)
        .map(pool => {
          return {
            ...pool,
            cashflowMonth: _formatMonthName(pool.cashflowMonth),
          };
        })
        .sortBy(pool => pool.cashflowMonth)
        .take(n)
        .value();
    },
    isExcluded(ex) {
      return !(ex.isPapa
        || (ex.excludeReason === 'zeroSum')
        || this.savingTransactionIdsFromHamster.find(id => id === ex.transactionId));
    },
    lastNReactiveCashflows(n) {
      return _.chain(this.allCashflows)
        .map(cashflow => reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL))
        .reverse()
        .slice(1, 1 + n)
        .value();
    },
    totalAccumulation(accBreakDown) {
      return accBreakDown.cashflowBalance
           + accBreakDown.savingEnvelopesActualsSum
           + accBreakDown.oneTimeSavingActualSum
           + accBreakDown.expenseExclusionsThatAreNotSavings
           + accBreakDown.incomeExclusionsThatAreNotSavings;
    },
    lastNexcludedTransactions(n) {
      const res = this.lastNReactiveCashflows(n).flatMap(cashflow => _.chain(cashflow.excluded)
        .filter(this.isExcluded)
        .map(ex => {
          return { ...ex, amount: (ex.incomeAmount ?? ex.billingAmount), isChecked: true };
        })
        .reject(ex => Math.round(ex.amount) === 0)
        .value());
      return res;
    },
    setNumberOfMonths(val) {
      this.numberOfMonths = val;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.money {
  color: $riseup-green;
  font-weight: bold;
}

.savings {
  color: $riseup-orange;
  font-weight: bold;
}

.asset {
  font-weight: bold;
}
</style>
