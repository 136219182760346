<template>
  <div class="super-discount-content">
    <simple-image-page
      :img-src="require('@/assets/super-discount/cart.png')">
      <template v-slot:textSlot>
        <div class="simple-page-content">
          <div class="ri-font-24 ri-bold-title margin-bottom-s">
            סידרנו לך הנחה על הקניות בסופרים של רשת יינות ביתן
          </div>
          <div class="text-line">
            ההטבה נותנת לך לרכוש <span class="bold">תו קניה בהנחה של 7%</span>. אפשר לחסוך ככה מאות שקלים בחודש.
          </div>
          <div class="shops-header">
            <span class="bold">רשימת החנויות בהן התו תקף:</span>
          </div>
          <div class="logos">
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/super1.png">
            </div>
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/super2.png">
            </div>
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/super3.png">
            </div>
          </div>
          <div class="logos">
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/super4.png">
            </div>
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/super5.png">
            </div>
            <div class="logo-container">
              <img class="logo" src="@/assets/super-discount/carrefour.png">
            </div>
          </div>
        </div>
      </template>
    </simple-image-page>
    <div class="summary-row">
      <expandable-summary-row  text-style="medium"
                               :with-shadow=false
                               :is-expandable=true
                               :header-text="'כמה פרטים נוספים'"
                               @expanded="detailsExpanded">
        <template v-slot:content>
          <div class="answer">
            <ul class="bullets">
              <li>ניתן לקנות תווים בין 50 ל־1,000 ₪.</li>
              <li>את התו תקבלו ב-SMS למספר שתקלידו.</li>
              <li>בשימוש התו בקניות אונליין, אפשר להשתמש רק בתו אחד בכל בקנייה.</li>
              <li>התווים בתוקף ל־5.5 שנים, וניתן לממש בקניות נפרדות.</li>
              <li>רייזאפ תקבל מ׳יינות ביתן׳ עמלה של 1.5% עבור כל קניית תו באתר של ״תו פלוס״.</li>
              <li>רכישת התו, הפעלתו ומימושו באחריות ׳יינות ביתן׳ - <span class="link" @click="termsClicked">כאן אפשר לקרוא את תנאי השימוש.</span></li>
              <li>אנחנו עובדים על שיתופי פעולה נוספים, נעדכן!</li>
            </ul>
          </div>
        </template>
      </expandable-summary-row>
    </div>
    <div class="sticky-container">
      <separator class="separator" />
      <div class="button-container">
        <div class="ri-font-18 margin-bottom-m">
          <span class="bold">לניצול ההטבה</span>
          <span>הזינו במסך הבא את הקוד:</span>
        </div>
        <div class="ri-font-40 margin-bottom-l">203310</div>
        <riseup-button class="button"
                       :action="buttonClicked"
                       :title="'אני רוצה תו קניה בהנחה!'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import SimpleImagePage from '@/base-components/SimpleImagePage';
import BaseUI from '@riseupil/base-ui';
// eslint-disable-next-line max-len
import ExpandableSummaryRow from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ExpandableSummaryRow';

export default {
  name: 'SuperDiscountPage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ExpandableSummaryRow,
    SimpleImagePage,
    Separator: BaseUI.Separator,
  },
  data() {
    return {
      variant: '',
    };
  },
  created() {
    this.variant = this.$route.query.group;
    Segment.trackUserInteraction('SuperDiscount_Entered', { variant: this.variant });
  },
  methods: {
    buttonClicked() {
      Segment.trackUserInteraction('SuperDiscountBuy_Clicked', { variant: this.variant });
      window.open('https://tavplus-business.mltp.co.il/', '_blank');
    },
    detailsExpanded() {
      Segment.trackUserInteraction('SuperDiscountDetails_Clicked', { variant: this.variant });
    },
    termsClicked() {
      Segment.trackUserInteraction('SuperDiscountTerms_Clicked', { variant: this.variant });
      window.open('https://tavplus-business.mltp.co.il/terms.pdf', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
.super-discount-content {
  .bold {
    font-weight: bold;
  }
  .simple-page-content {
    .text-line {
      margin-bottom: 24px;
    }
    .shops-header {
      margin-bottom: 10px;
    }
    .logos {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .logo-container {
        width: 100px;
        height: 100px;
        padding: 17px;
        border: 1px solid #DDDDDD;
        border-radius: 7px;
        .logo {
          width: 100%;
        }
      }
    }
  }
  .summary-row {
    margin-top: -20px;
    margin-bottom: 30px;
    border-top: 1px solid #DCDCDC;
  }
  .answer {
    margin-bottom: 20px;
    padding: 24px;
    .bullets{
      font-size: 18px;
      line-height: 26px;
      .link {
        font-weight: bold;
        color: $riseup_blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .separator {
    margin-bottom: 24px;
  }
  .sticky-container {
    background-color: $riseup_white;
    position: sticky;
    bottom: 0;
    .button-container{
      padding: 0 24px 24px;
      .button {
        width: 100%;
      }
    }
  }
}

</style>
