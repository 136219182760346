<template>
  <div class="waiting-credentials-card" :class="{ loading }">
    <connected-credentials-card
      class="invalid-credentials-card"
      :source="source"
      :source-type="source.type"
      :name="source.name"
      :sourceName="source.sourceName"
      :status="source.status"
      :accounts="source.accounts"
      :enable-menu="true">
      <template v-slot:buttons>
        <ValidationObserver ref="observer" slim>
          <pre-filled-input-field
                            class="customer-id-field"
                            :fieldText="'מספר ת.ז.'"
                            :fieldValue="consentInfo.userIdInBank"/>
        </ValidationObserver>
        <div class="actions">
          <single-click-button
            class="cta-button"
            :size="'slim'"
            :variant="'primary'"
            :title="buttonTitle"
            :disabled="showUnsupportedDesktop"
            :action="redirectToGetAccessCode" />
          <div v-if="showUnsupportedDesktop" class="unsupported-desktop-notice">
            <base-tool-tip icon-path="/images/info-icon-blue.svg">
              <span class="bold">
              אפשר לחדש רק באפליקצית {{ source.displayName }} ולכן נצטרך לעבור למכשיר הנייד.
              </span>
              אפשר להמשיך בקלות בעזרת הלינק שיש לך בוואטסאפ
            </base-tool-tip>
          </div>
        </div>
      </template>
    </connected-credentials-card>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import DDLogs from '@/DDLogs';
import ConnectedCredentialsCard from '@/base-components/onboarding/ConnectedCredentialsCard';
import Segment from '../../../../Segment';
import PreFilledInputField from '../../../card-parts/PreFilledInputField.vue';
import SingleClickButton from '../../../SingleClickButton';
import AggregatorApi from '../../../../api/AggregatorApi';
import BaseToolTip from '../../../onboarding/BaseToolTip';

export default {
  name: 'OpenBankingRenewalCredentialsCard',
  components: {
    SingleClickButton,
    ConnectedCredentialsCard,
    PreFilledInputField,
    BaseToolTip,
  },
  props: [
    'source',
  ],
  data() {
    return {
      loading: false,
      error: null,
      consentInfo: {},
    };
  },
  async created() {
    this.consentInfo = await AggregatorApi.getConsentInfo(this.source.credentialsId);
  },
  computed: {
    ...mapState('genericError', ['ERROR_TYPES']),
    buttonTitle() {
      return 'לחידוש ההסכמה';
    },
    showUnsupportedDesktop() {
      return this.source && this.source.openBanking && !this.source.openBanking.allowDesktop && !this.isMobile;
    },
    isMobile() {
      const mobileAgents = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return _.some(mobileAgents, toMatchItem => navigator.userAgent.match(toMatchItem));
    },
  },
  methods: {
    ...mapActions('genericError', ['setErrorType']),
    async redirectToGetAccessCode() {
      try {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          this.error = 'המספר אינו תקין';
          return;
        }
        Segment.trackUserInteraction('ReconnectOBKClicked', { credentialsId: this.source.credentialsId, source: this.source.sourceName });
        const url = await AggregatorApi.updateConsent(
          this.source.openBanking.aggregatorBankId,
          this.source.openBankingConsentId,
          this.source.credentialsId,
        );
        Segment.trackUserGot('RedirectToOpenBanking', { renewCreds: true, source: this.source.name });
        DDLogs.log('Redirecting to OpenBanking', { url });
        window.location.href = url;
      } catch (error) {
        // TODO: are other edges cases we want to support here?
        DDLogs.error('Failed getting redirect url', { error, source: this.source.name });
        this.setErrorType(this.ERROR_TYPES.SERVER_ERROR);
      }
    },
  },
};
</script>

<style lang="scss">
.customer-id-field input {
  direction: ltr;
  text-align: right;
}
</style>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.waiting-credentials-card {
  font-family: SimplerPro;
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 0 $riseup_gray_1;

  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .waiting-card-header {
    display: inline-block;
    width: 100%;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    background-color: $riseup_yellow;
    color: $riseup_white;
    font-weight: bold;
    padding-right: 20px;
  }

  .bank-description {
    padding-top: 24px;
    display: flex;

    .bank-title {
      @include card-title;
    }

    &.invalid {
      margin-right: 30px;
    }
  }

  .nickname {
    margin-right: 50px;
    margin-top: 8px;
    font-size: 20px;

    &.invalid {
      margin-right: 30px;
    }
  }

  .actions {
    text-align: center;

    .riseup-button {
      width: 100%;
    }

    .cta-button {
      margin-top: 20px;
    }
  }

  .customer-id-field {
    margin: 30px 0px 0px;
  }

  .unsupported-desktop-notice {
    margin-top: 15px;
    text-align: right;
    line-height: 20px;
    font-size: 14px;

    .bold {
      font-weight: bold;
    }
  }
}
</style>
