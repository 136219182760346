<template>
  <o-b-k-connection-failure v-if="obkConnectionError" :on-close="openCreateCredentialsFlow" />
  <sticky-cta-page v-else :pageBackgroundColor="ctaBackgroundColor" :ctaProps="ctaProps" @click="checkAndContinue">
    <div class="create-credentials">
      <div class="ri-large-headline ri-alte padding-horizontal-ml padding-top-ml padding-bottom-sm">
        חשבונות האשראי המחוברים לשירות
      </div>
      <div class="add-credit-card-description">
        <div class="subtitle ri-large-body">הגישה לחשבונות האשראי מאפשרת למערכת להציג לך את ההוצאות בצורה מדויקת ועדכנית</div>
      </div>
      <separator/>
      <div class="credit-card-section">
        <div class="credit-card-section-content">
          <template v-if="hasCreditCards">
            <div class="account">חשבונות אשראי מחוברים</div>
            <div v-for="credentials in credentialsList" :key="credentials._id">
              <connected-credentials-card v-if="credentials.sourceType === 'card'"
                                          class="credit-card-wrapper"
                                          :source-type="'card'"
                                          :name="credentials.name"
                                          :sourceName="credentials.sourceName"
                                          :status="credentials.status"
                                          :accounts="credentials.accounts"/>
              <div v-else v-for="accountBySource in bankConnectedAccount(credentials)" :key="accountBySource.sourceName">
                <connected-credentials-card class="credit-card-wrapper"
                                            :source-type="'card'"
                                            :name="''"
                                            :sourceName="accountBySource[0].source"
                                            :status="credentials.status"
                                            :accounts="accountBySource"/>
              </div>
            </div>
          </template>
          <div class="account" v-if="nonConnectedExpenses.length > 0">חשבונות האשראי שמהערכת זיהתה</div>
          <div v-for="(creditCardExpenses, sourceName) in expensesBySource" :key="sourceName">
            <none-connected-credit-card-source-card
              class="credit-card-wrapper"
              :source-name="sourceName"
              :connect-credit-card="() => onSaveCardCredentialsClicked(sourceName)"
              :credit-card-expenses="creditCardExpenses"/>
          </div>
          <separator/>
          <add-credentials-card class="new-account-wrapper" :title="addMoreTitle" :add-new-credentials="openCreateCredentialsFlow"/>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import NoneConnectedCreditCardSourceCard from '@/base-components/onboarding/NoneConnectedCreditCardSourceCard';
import ConnectedCredentialsCard from '@/base-components/onboarding/ConnectedCredentialsCard';
import AddCredentialsCard from '@/base-components/onboarding/AddCredentialsCard';
import OBKConnectionFailure from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/OBKConnectionFailure';
import Segment from '@/Segment';
import NoCreditCardsContinuePopup from '@/base-components/onboarding/NoCreditCardsContinuePopup.vue';
import CreateCredentialsFlow from '../create-credentials-flow/CreateCredentialsFlow';

export default {
  name: 'CreditCardsCredentialsOnboarding',
  components: {
    Separator: BaseUI.Separator,
    NoneConnectedCreditCardSourceCard,
    ConnectedCredentialsCard,
    AddCredentialsCard,
    OBKConnectionFailure,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      nextDisabled: false,
      ctaBackgroundColor: BaseUI.Colors.riseupWhite,
    };
  },
  async created() {
    this.startLoading(this.$options.name);
    await this.fetchCreditCardDetails();
    this.doneLoading(this.$options.name);
    Segment.trackUserGot('CreditCardConnectionEntered',
      {
        newBrand: true,
        numOfConnectedCreditCards: this.numOfConnectedCreditCards,
        numOfConnectedBankCards: this.numOfConnectedBankCards,
        totalConnectedCards: this.numOfConnectedBankCards + this.numOfConnectedCreditCards,
      });
    if (!this.obkConnectionError && this.sourceName) {
      this.openCreateCredentialsFlow();
    }
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState('creditCardExpenses', ['creditCardExpenses']),
    ...mapState('credentials', ['credentialsList']),
    ...mapState('session', ['customerId']),
    ...mapState('onboardingState', ['isLoading', 'connectionSuccess']),
    ...mapGetters('creditCardExpenses', ['expensesBySource', 'nonConnectedExpenses']),
    ...mapGetters('onboardingState', ['sourceName', 'obkConnectionError']),
    ctaProps() {
      return {
        buttonProps: {
          title: 'אפשר להמשיך',
          loading: this.isLoading,
          disabled: this.nextDisabled,
        },
        backgroundColor: BaseUI.Colors.riseupWhite,
      };
    },
    validCreditCardCreds() {
      return _.filter(this.credentialsList, { status: 'valid' });
    },
    hasCreditCards() {
      return this.numOfConnectedCreditCards > 0 || this.numOfConnectedBankCards > 0;
    },
    addMoreTitle() {
      return this.hasCreditCards ? 'לחיבור חשבון אשראי נוסף לשירות' : 'לחיבור חשבון אשראי לשירות';
    },
    numOfConnectedCreditCards() {
      return _.chain(this.credentialsList)
        .filter(credentials => credentials.type === 'card')
        .map(creds => _.map(creds.accounts, 'accountNumberPiiId'))
        .flatMap()
        .uniq()
        .value().length;
    },
    numOfConnectedBankCards() {
      return _.chain(this.credentialsList)
        .filter(credentials => credentials.type === 'bank')
        .map(creds => _.chain(creds.accounts)
          .filter(account => !account.isExcluded && account.sourceType === 'card')
          .map(account => account.accountNumberPiiId)
          .value())
        .flatMap()
        .uniq()
        .value().length;
    },
  },
  methods: {
    ...mapActions('creditCardExpenses', ['fetchCreditCardsExpenses']),
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    ...mapMutations('onboardingState', ['setPageParams']),
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    ...mapActions('credentials', ['fetchCredentials']),
    ...mapActions('modalRootStore', ['openModal']),
    cardConnection(sourceName) {
      this.setPageParams({ sourceName });
      this.openModal({
        component: CreateCredentialsFlow,
        props: {
          sourceType: 'card',
          allowBackToSourceSelection: false,
        },
        popupAlignment: 'full-screen',
      });
    },
    bankConnectedAccount(bankCreds) {
      return _.groupBy(_.filter(bankCreds.accounts, account => account.sourceType === 'card' && account.isExcluded === false), 'source');
    },
    async fetchCreditCardDetails() {
      await this.fetchCreditCardsExpenses();
      await this.fetchCredentials();
    },
    async onSaveCardCredentialsClicked(sourceName) {
      Segment.trackUserInteraction('ConnectCreditCardClicked', { newBrand: true, sourceName });
      this.cardConnection(sourceName);
    },
    async onCreditCardConnectionNext() {
      this.nextDisabled = true;
      Segment.trackUserInteraction('CreditCardsConnectionNextClicked',
        {
          newBrand: true,
          numOfConnectedCreditCards: this.numOfConnectedCreditCards,
          numOfConnectedBankCards: this.numOfConnectedBankCards,
          totalConnectedCards: this.numOfConnectedBankCards + this.numOfConnectedCreditCards,
        });
      await this.onboardingPageSubmit();
    },
    checkAndContinue() {
      if (this.validCreditCardCreds.length === 0) {
        this.openModal({
          component: NoCreditCardsContinuePopup,
          props: { nextPage: this.onCreditCardConnectionNext },
        });
      } else {
        this.onCreditCardConnectionNext();
      }
    },
    openCreateCredentialsFlow() {
      this.openModal({
        component: CreateCredentialsFlow,
        props: {
          sourceType: 'card',
        },
        popupAlignment: 'full-screen',
      });
    },
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.startLoading(this.$options.name);
        await this.fetchCreditCardsExpenses();
        await this.fetchCredentials();
        this.doneLoading(this.$options.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.create-credentials {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $riseup_dark_green;

  .add-credit-card-description {
    @include responsive-padding;

    .subtitle {
      margin-bottom: 40px;
    }
  }

  .tool-tip-wrapper {
    @include responsive-padding;
    margin-bottom: 40px;

    .info-tool-tip {
      width: 100%;

      .info-text {
        font-family: SimplerPro;
        font-size: 14px;
        line-height: 1.36;
        height: 100%;

        .bold {
          font-weight: bold
        }
      }
    }
  }

  .credit-card-section {
    width: 100%;
    background-color: $riseup_gray_-1;
    padding-bottom: 6px;

    .credit-card-section-content {
      @include responsive-padding;

      .account {
        font-size: 14px;
        font-weight: bold;
        color: $riseup_black;
        margin: 30px 0 30px;
      }

      .credit-card-wrapper {
        margin-bottom: 24px;
      }

      .new-account-wrapper {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}

</style>
