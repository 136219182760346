<template>
  <div class="page-section-header" :class="color">
    <span class="section-title">
      <img class="icon" :src="getIconUrl()"/>
      {{ titleText }}
    </span>
    <div class="section-seperator"></div>
  </div>
</template>

<script>

export default {
  name: 'PageSectionHeader',
  props: [
    'titleText',
    'color',
    'icon',
  ],
  methods: {
    getIconUrl() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/icons/${this.icon}.svg`);
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../scss/mixins';

  .page-section-header {
    margin-bottom: 15px;

    .section-title {
      font-weight: bold;
      color: $riseup_white;
      font-size: 14px;
      line-height: 14px;
      padding: 3px 3px 2px 6px;

      .icon {
        vertical-align: middle;
        padding-bottom: 2px;
      }
    }

    .section-seperator {
      height: 2px;
    }

    &.coral {
      .section-title, .section-seperator {
        background: $riseup_danger_red;
      }
    }

    &.green {
      .section-title, .section-seperator {
        background: $riseup_green;
      }
    }

    &.black {
      .section-title, .section-seperator {
        background: $riseup_black;
      }
    }

    &.mustard {
      .section-title, .section-seperator {
        background: $riseup_yellow;
      }
    }
  }
</style>
