<template>
  <sticky-cta-page :page-background-color="pageBackgroundColor" :cta-props="ctaProps" @click="next">
    <image-header-page :image-header-props="imageHeaderProps" sticky-header>
      <template v-slot:header>
        <div class="headers">
          <span class="ri-body">יצאת לדרך, איזה כיף!</span>
          <div class="flex-column">
            <span class="ri-large-headline">35 ימי נסיון</span>
            <span class="ri-large-headline">מתחילים עכשיו!</span>
          </div>
        </div>
      </template>
      <div class="content">
        <timeline-items :timeline-items="timelineItems"/>
        <div v-if="benefitId" class="benefit-box padding-vertical-ml padding-horizontal-l">
          <benefit-notification-box-v2 flavor="signup-timeline" />
        </div>
      </div>
    </image-header-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import TimelineItems from './TimelineItems.vue';

export default {
  name: 'TimelineIntro',
  components: {
    BenefitNotificationBoxV2,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    TimelineItems,
  },
  data() {
    return {
      imageHeaderProps: {
        title: '',
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
        imgSrc: '/images/onboarding/ob-timeline-intro.svg',
        textColor: BaseUI.Colors.riseupDarkGreen,
        bottomSeparator: false,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        imgHeight: 185,
      },
      pageBackgroundColor: BaseUI.Colors.riseupBeige,
      ctaProps: {
        backgroundColor: BaseUI.Colors.riseupBeige,
        buttonProps: {
          title: 'הבנתי, אפשר להמשיך',
          loading: false,
        },
      },
    };
  },
  async created() {
    Segment.trackUserGot('TimelineIntro_PageEntered');
    Segment.trackUserGot('TimelineIntro_PageEntered_Marketing');
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    async next() {
      this.ctaProps.buttonProps.loading = true;
      Segment.trackUserInteraction('TimelineIntro_NextClicked');
      Segment.trackUserInteraction('TimelineIntro_NextClicked_Marketing');
      await this.onboardingPageSubmit();
      this.ctaProps.buttonProps.loading = false;
    },
  },
  computed: {
    ...mapState('benefits', ['benefitId']),
    timelineItems() {
      return [
        {
          title: 'סיום הרשמה',
          description: 'הצטרפת אלינו בהצלחה לשירות',
          checked: true,
        },
        {
          title: 'היום, תקופת הנסיון התחילה',
          description: 'יצאנו לדרך איזה כיף!',
          checked: true,
        },
        {
          title: 'בשלב הבא',
          description: 'חיבור חשבונות וקבלת תזרים ראשון',
          checked: false,
          iconName: 'lock_open',
        },
        {
          title: 'בעוד 35 ימים',
          description: this.benefitId
            ? 'תקופת הנסיון מסתיימת ואפשר להמשיך ליהנות מהשירות בהטבה שמגיעה לך'
            : 'סוף תקופת הנסיון. אפשר להינות מהשירות במינוי בתשלום של 45 ₪ בחודש',
          checked: false,
          iconName: 'notifications',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

::v-deep .title {
  justify-content: flex-start !important;
}

.headers {
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: $ri-spacing-ml 0;
  white-space: pre-wrap;
  color: $riseup_dark_green;
}

.content {
  height: 100%;
  background-color: $riseup-beige;
}
</style>
