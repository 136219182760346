import { render, staticRenderFns } from "./MoneyMoonJoinPage.vue?vue&type=template&id=acafb888&scoped=true&"
import script from "./MoneyMoonJoinPage.vue?vue&type=script&lang=js&"
export * from "./MoneyMoonJoinPage.vue?vue&type=script&lang=js&"
import style0 from "./MoneyMoonJoinPage.vue?vue&type=style&index=0&id=acafb888&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acafb888",
  null
  
)

export default component.exports