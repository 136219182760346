<template>
  <banner-page title="מהן ההוצאות הקבועות החודשיות שלך?"
               :background-position="banner.backgroundPosition"
               :background-color="banner.backgroundColor"
               imgSrc="/images/onboarding/ob-expense-investigation.svg">
    <sticky-cta-page :pageBackgroundColor="backgroundColor" :ctaProps="ctaProps" @click="doneAnswering">
      <div class="expense-investigation-onboarding">
        <template v-if="foundFixedExpenses">
          <expandable-transaction-check-list
            :is-expandable="false"
            :toggleCheck="togglePredictedIsFixed">
            <template v-slot:subtitle>
              <div class="subtitle ri-large-body margin-bottom-l">
                המערכת צופה שההוצאות האלו
                <span class="ri-large-bold-body"> ירדו כל חודש בסכום די זהה. </span>
                אם זיהית כאן הוצאה חד פעמית, אפשר להסיר אותה:
              </div>
              <info-tool-tip
                class="tool-tip-wrapper"
                text="איך המערכת מחשבת מהן ההוצאות הקבועות שלך?"
                popup-text="אפשר לקרוא כאן."
                event-name="ExpenseInvestigationTooltipClicked"
                icon-path="/images/info-icon-blue.svg"
                :popup-component="expenseInvestigationHelpPopup"/>
            </template>
          </expandable-transaction-check-list>
          <div class="full-width" v-for="(expenses, category, index) in this.predictedExpenseQuestionsList" :key="category">
            <expandable-transaction-check-list
              color-variant="red"
              :title="category"
              :is-expandable="true"
              :transaction-list="expenses"
              :expand-on-create="index === 0"
              :toggleCheck="togglePredictedIsFixed"/>
          </div>

        </template>
        <div v-if="foundFixedExpenses" class="empty-block-divider"></div>
        <separator v-if="foundFixedExpenses"/>
        <expandable-transaction-check-list
          v-if="foundActualQuestions"
          :isExpandable="foundFixedExpenses"
          color-variant="red"
          :title="varibaleExpensesTitle"
          :transaction-list="actualExpenseQuestionsList"
          :toggleCheck="toggleActualIsFixed">
          <template v-slot:subtitle>
            <div v-if="!foundFixedExpenses" class="subtitle ri-large-body margin-bottom-l">
              המערכת זיהתה רק הוצאות שלא הולכות לחזור בחודשים הקרובים. אולי פספסנו משהו ו
              <span class="ri-large-bold-body">יש כאן הוצאה קבועה?</span>
              אם כן, אפשר לסמן אותה.
            </div>
          </template>
        </expandable-transaction-check-list>
      </div>
    </sticky-cta-page>
  </banner-page>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import consts from '@/constants/onboarding-state';
import InfoToolTip from '@/base-components/onboarding/InfoToolTip.vue';
import ExpandableTransactionCheckList from '@/base-components/onboarding/ExpandableTransactionCheckList.vue';
import Segment from '@/Segment';
import ExpenseInvestigationHelpPopup
  from '@/base-components/onboarding/help-popups/ExpenseInvestigationHelpPopup.vue';

export default {
  name: 'ExpenseInvestigationOnboarding',
  components: {
    Separator: BaseUI.Separator,
    InfoToolTip,
    ExpandableTransactionCheckList,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      banner: {
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.PINK,
      },
      backgroundColor: BaseUI.Colors.riseupWhite,
      ctaProps: {
        buttonProps: {
          loading: false,
          title: 'סיימתי, אפשר להמשיך',
        },
        backgroundColor: BaseUI.Colors.riseupWhite,
      },
    };
  },
  async created() {
    this.ctaProps.buttonProps.loading = true;
    await this.fetchExpenseQuestions();
    const numberOfFixedTransactions = this.predictedExpenseQuestions.filter(transaction => transaction.isFixed).length;
    const numberOfVariableTransactions = this.actualExpenseQuestions.length;
    Segment.trackUserGot('FixedExpensesReviewEntered', { newBrand: true, numberOfFixedTransactions, numberOfVariableTransactions });
    Segment.trackUserGot('FixedExpensesReviewEntered_Marketing', { newBrand: true });
    this.ctaProps.buttonProps.loading = false;
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapState('expenseInvestigationQuestions', ['actualExpenseQuestions', 'predictedExpenseQuestions']),
    ...mapGetters('expenseInvestigationQuestions', ['predictedExpenseByCategory', 'expenseInputs', 'fixedExpenses', 'canceledEnvelopes']),
    ...mapState('websocket', ['socketMessage']),
    ...mapState('onboardingState', ['isLoading']),
    expenseInvestigationHelpPopup() {
      return ExpenseInvestigationHelpPopup;
    },
    currentPageName() {
      return consts.ONBOARDING_PAGE.EXPENSE_INVESTIGATION;
    },
    predictedExpenseQuestionsList() {
      return _.mapValues(this.predictedExpenseByCategory, categoryList => _.map(categoryList, question => {
        return {
          businessName: question.businessName,
          amount: question.billingAmount,
          isChecked: question.isFixed,
          envelopeId: question.envelopeId,
        };
      }));
    },
    actualExpenseQuestionsList() {
      return _.map(this.actualExpenseQuestions, question => {
        return {
          businessName: question.businessName,
          amount: question.billingAmount,
          isChecked: question.isFixed,
          transactionId: question.transactionId,
        };
      });
    },
    foundActualQuestions() {
      return this.actualExpenseQuestions.length > 0;
    },
    foundFixedExpenses() {
      return this.predictedExpenseQuestions.length > 0;
    },
    varibaleExpensesTitle() {
      return this.foundFixedExpenses
        ? 'אולי פספסנו משהו?'
        : 'לא זיהינו הוצאות קבועות בחשבון';
    },
  },
  methods: {
    ...mapActions('expenseInvestigationQuestions', ['fetchExpenseQuestions']),
    ...mapActions('onboardingState', ['expenseInvestigationSubmit']),
    ...mapMutations('expenseInvestigationQuestions', ['setPredictedIsFixed', 'setActualIsFixed']),
    ...mapMutations('onboardingState', ['setLoadingState']),
    async doneAnswering() {
      this.ctaProps.buttonProps.loading = true;
      this.setLoadingState(true);
      const numberOfRemovedFixed = this.canceledEnvelopes.length;
      const numberOfAddedVariables = this.fixedExpenses.length;
      Segment.trackUserInteraction('FixedExpensesReviewNextClicked', { newBrand: true, numberOfRemovedFixed, numberOfAddedVariables });
      Segment.trackUserInteraction('FixedExpensesReviewNextClicked_Marketing', { newBrand: true });
      await this.expenseInvestigationSubmit(this.expenseInputs);
      this.ctaProps.buttonProps.loading = false;
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    togglePredictedIsFixed(question) {
      const isChecked = !question.isChecked;
      this.setPredictedIsFixed({ envelopeId: question.envelopeId, isFixed: isChecked });
    },
    toggleActualIsFixed(question) {
      const isChecked = !question.isChecked;
      this.setActualIsFixed({ transactionId: question.transactionId, isFixed: isChecked });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.expense-investigation-onboarding {
  @include white-page-frame;
  height: 100%;
  width: 100%;

  .tool-tip-wrapper {
    margin-bottom: 35px;
    width: 100%;
  }

  .subtitle {
    color: $riseup_dark_green;
  }

  .empty-block-divider {
    width: 100%;
    height: 60px;
    background-color: $riseup_gray_-1;
  }
}
</style>
