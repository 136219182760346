<template>
  <loader v-if="loading"/>
  <o-b-k-connection-failure v-else-if="obkConnectionError" :on-close="openCreateCredentialsFlow" />
  <div v-else class="bank-credentials-onboarding flex-column flex-1">
    <div class="ri-large-headline ri-alte padding-horizontal-ml padding-top-ml padding-bottom-sm">
      החשבונות המחוברים לשירות
    </div>
    <div class="description">
      <div class="padding-bottom-ml ri-large-body">הגישה לחשבון הבנק מאפשרת למערכת להציג לך את ההכנסות בצורה מדויקת</div>
      <info-tool-tip
        class="tool-tip-wrapper"
        :text="'אילו חשבונות בנק לכלול בתזרים החודשי ואילו עדיף שלא? '"
        :popup-text="'אפשר לקרוא כאן.'"
        :event-name="'AccountSelectionTooltipClicked'"
        :popup-component="accountInvestigationHelpPopup"/>
    </div>
    <separator/>
    <div class="accounts-container">
      <div class="accounts-section-content">
        <template v-if="bankCredentials && bankCredentials.length > 0">
          <div v-for="bank in bankCredentials" :key="bank._id">
            <connected-credentials-card
              class="bank-wrapper"
              :source-type="'bank'"
              :name="bank.name"
              :sourceName="bank.sourceName"
              :status="bank.status"
              :accounts="bank.accounts">
              <template v-slot:buttons v-if="bank.status === 'partiallyAuthorized'">
                <partially-authorized-retry-scrape-button/>
              </template>
            </connected-credentials-card>
          </div>
        </template>
        <separator/>

        <template v-if="skipCCInOB">
          <div class="connected-cards-wrapper" v-if="connectedCards && connectedCards.length > 0">
            <div v-for="card in connectedCards" :key="card._id">
              <connected-credentials-card
                class="bank-wrapper"
                :source-type="'card'"
                :name="card.name"
                :sourceName="card.source"
                :status="'valid'"
                :accounts="card.accounts" />
            </div>
          </div>
          <separator/>
        </template>

        <add-credentials-card class="new-account-wrapper" :title="addCredentialsTitle" :add-new-credentials="openCreateCredentialsFlow"/>
      </div>
    </div>
    <div class="padding-ml">
      <riseup-button title="אפשר להמשיך"
                     :loading="isLoading"
                     :disabled="(!isLoading && connectedBankCredentials.length === 0)"
                     @click="nextPage"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import featureFlag from '@/api/featureFlag';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import PartiallyAuthorizedRetryScrapeButton
  from '@/base-components/credentials/credentials-card/open-banking/PartiallyAuthorizedRetryScrapeButton';
import Loader from '@/base-components/Loader';
import ConnectedCredentialsCard from '@/base-components/onboarding/ConnectedCredentialsCard';
import OBKConnectionFailure
  from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/OBKConnectionFailure';
import InfoToolTip from '@/base-components/onboarding/InfoToolTip.vue';
import AddCredentialsCard from '@/base-components/onboarding/AddCredentialsCard.vue';
import Segment from '@/Segment';
import AccountInvestigationHelpPopup
  from '@/base-components/onboarding/help-popups/AccountInvestigationHelpPopup.vue';
import CreateCredentialsFlow from '../create-credentials-flow/CreateCredentialsFlow';

export default {
  name: 'BankCredentialsOnboarding',
  components: {
    OBKConnectionFailure,
    Separator: BaseUI.Separator,
    InfoToolTip,
    AddCredentialsCard,
    ConnectedCredentialsCard,
    Loader,
    PartiallyAuthorizedRetryScrapeButton,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      loading: false,
      skipCCInOB: false,
    };
  },
  async created() {
    this.loading = true;
    await this.fetchAccounts();
    await this.fetchCredentials();
    this.skipCCInOB = (await featureFlag.getTreatment('skip_onboarding_credit_cards')) === 'on';
    Segment.trackUserGot('BankConnectionEntered');
    if (!this.obkConnectionError && (_.isEmpty(this.bankCredentials) || this.sourceName || this.accountQuestions.length > 0)) {
      this.openCreateCredentialsFlow('bank');
    }
    this.loading = false;
  },
  computed: {
    ...mapState('accountInvestigationOnboarding', ['accountQuestions']),
    ...mapState('onboardingState', ['isLoading']),
    ...mapState('credentials', ['credentialsList']),
    ...mapGetters('accountInvestigationOnboarding', ['noIncludedAccounts', 'accountInputs']),
    ...mapGetters('onboardingState', ['sourceName', 'obkConnectionError']),
    ...mapGetters('credentials', ['partiallyAuthorizedCredentialsList', 'accountsList']),
    accountInvestigationHelpPopup() {
      return AccountInvestigationHelpPopup;
    },
    bankCredentials() {
      return _.filter(this.credentialsList, { type: 'bank' });
    },
    connectedCards() {
      return _.filter(this.accountsList, { sourceType: 'card' });
    },
    connectedBankCredentials() {
      return _.filter(this.credentialsList, { type: 'bank', status: 'valid' });
    },
    addCredentialsTitle() {
      if (this.skipCCInOB) {
        return 'להוספת חשבון בנק או אשראי';
      }
      return _.isEmpty(this.bankCredentials) ? 'לחיבור חשבון בנק' : 'אני רוצה לחבר חשבון בנק נוסף';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('accountInvestigationOnboarding', ['fetchAccounts']),
    ...mapActions('credentials', ['fetchCredentials']),
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    ...mapActions('onboardingState', ['onboardingPageSubmitWithLoading']),
    ...mapMutations('accountInvestigationOnboarding', ['toggleAccountIsIncluded']),
    ...mapMutations('onboardingState', ['setLoadingState', 'setPageName']),
    async nextPage() {
      Segment.trackUserInteraction('BankDetailsNextClicked');
      await this.onboardingPageSubmitWithLoading();
    },
    bankDisplayName(question) {
      return getSourceConfigurationBySourceName(question.source).displayName;
    },
    openCreateCredentialsFlow(sourceType) {
      const stringSourceType = _.isString(sourceType) ? sourceType : '';
      const props = this.skipCCInOB ? { sourceType: stringSourceType } : { sourceType: 'bank' };
      this.openModal({
        component: CreateCredentialsFlow,
        props,
        popupAlignment: 'full-screen',
      });
    },
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.startLoading(this.$options.name);
        await this.fetchCredentials();
        await this.fetchAccounts();
        this.doneLoading(this.$options.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.bank-credentials-onboarding {

  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $riseup_dark_green;

  .description {
    @include responsive-padding;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: $riseup_blue;
      margin-bottom: 20px;
    }

    .tool-tip-wrapper {
      color: $riseup_black;
      margin-bottom: 40px;
    }

  }

  .accounts-container {
    width: 100%;
    background-color: $riseup_beige;
    @include responsive-padding;
    padding-top: 25px;
    padding-bottom: 25px;

    .accounts-section-content {
      margin-bottom: 30px;

      .bank-wrapper {
        margin-bottom: 24px;
      }

      .connected-cards-wrapper {
        margin-top: 30px;
      }
    }

    .new-account-wrapper {
      margin-top: 30px;
    }
  }
}
</style>
