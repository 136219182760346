<template>
  <sticky-cta-page :pageBackgroundColor="ctaBackgroundColor" :ctaProps="ctaProps" @click="next">
    <div class="prepare-credentials-page">
      <div class="prepare-credentials-title ri-large-headline ri-alte">
        לשלב הבא צריך להכין את פרטי ההתחברות לאתר הבנק והאשראי. כוס קפה והתזרים שלך מוכן!
      </div>
      <div class="prepare-credentials-content ri-large-body ">
        <div class="content padding-horizontal-ml">
          <div >בעזרת פרטי ההתחברות המערכת תיגש לחשבונות שלך ותבנה לך תכף תזרים מדויק</div>
          <img src="/images/onboarding/ob-prepare-creds.png" class="padding-top-ml padding-bottom-ml full-width">
        </div>
        <div class="questions">
          <div v-for="(text, index) in qnaContent" :key="index">
            <div class="padding-horizontal-ml padding-vertical-m">
              <expandable-section @expand-change="() => sendEvent(text.type)" :textColor="textColor">
                <template v-slot:header>
                  <div class="ri-bold-body">{{text.question}}</div>
                </template>
                <template v-slot:content>
                  {{ text.answer }}
                </template>
              </expandable-section>

            </div>
            <separator />
          </div>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'PrepareCredentials',
  components: {
    ExpandableSection: BaseUI.ExpandableSection,
    Separator: BaseUI.Separator,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      textColor: BaseUI.Colors.riseupDarkGreen,
      ctaBackgroundColor: BaseUI.Colors.riseupBeige,
      ctaProps: {
        buttonProps: {
          loading: false,
          title: 'לחיבור החשבונות',
        },
        backgroundColor: BaseUI.Colors.riseupBeige,
      },
      qnaContent: [
        {
          question: 'מה אם יש לי יותר מחשבון בנק אחד?',
          answer: 'אין בעיה, אפשר לחבר כמה חשבונות שרוצים ורייזאפ תייצר לך תזרים אחד מכולם.',
          type: 'multiple_banks',
        },
        {
          question: 'מה אם יש לי כמה כרטיסי אשראי?',
          answer: 'אין בעיה, אפשר לחבר כמה כרטיסי אשראי שרוצים ורייזאפ תייצר לך תזרים אחד מכולם.',
          type: 'multiple_credits',
        },
        {
          question: 'מה אם אנחנו זוג בלי חשבון בנק משותף?',
          // eslint-disable-next-line max-len
          answer: 'תוכלו לחבר את שני החשבונות והמערכת תייצר לכם תזרים אחד מכל החשבונות. זה יכול להיות פתרון מצוין להתנהל ביחד כלכלית בלי חשבון בנק משותף.',
          type: 'couple_different_banks',
        },
      ],
    };
  },
  async created() {
    EventBus.$emit('reset-scroll');
    Segment.trackUserGot('PrepareCredentials_PageEntered', { newBrand: true });
    Segment.trackUserGot('PrepareCredentials_PageEntered_Marketing', { newBrand: true });
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    async next() {
      this.ctaProps.buttonProps.loading = true;
      Segment.trackUserInteraction('PrepareCredentials_NextClicked', { newBrand: true });
      Segment.trackUserInteraction('PrepareCredentials_NextClicked_Marketing', { newBrand: true });
      await this.onboardingPageSubmit();
      this.ctaProps.buttonProps.loading = false;
    },
    sendEvent(type) {
      Segment.trackUserInteraction('PrepareCredentials_ExpandedBullet', { type });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.prepare-credentials-page {

  .prepare-credentials-title {
    background-color: $riseup_light_yellow;
    color: $riseup_dark_green;
    padding: $ri-spacing-ml $ri-spacing-ml $ri-spacing-sm $ri-spacing-ml;
  }

  .prepare-credentials-content {
    color: $riseup_dark_green;

    .content {
      background-color: $riseup_light_yellow;
    }

    .questions {
      background-color: $riseup_beige;
      color: $riseup_dark_green;
    }
  }
}

</style>
