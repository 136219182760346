<template>
  <div class="expandable-credit-card-expense-list">
    <div class="title-with-expand-collapse" v-on:click="toggleExpand">
      <div class="title">{{ title }}</div>
      <div class="image-container">
        <img v-if="isExpanded" src="/images/onboarding/icons/ic-chevron-up-black.svg">
        <img v-else src="/images/onboarding/icons/ic-chevron-down-black.svg">
      </div>
    </div>
    <collapse-transition>
      <credit-card-expenses-list v-if="creditCardExpenses && isExpanded"
                                 :credit-card-expenses="creditCardExpenses" class="list-with-shadow"/>
    </collapse-transition>
  </div>
</template>

<script>
import CreditCardExpensesList from '@/base-components/onboarding/CreditCardExpensesList';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ExpandableCreditCardExpenseList',
  components: {
    CollapseTransition: BaseUI.CollapseTransition,
    CreditCardExpensesList,
  },
  props: {
    creditCardExpenses: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.expandable-credit-card-expense-list {
  .title-with-expand-collapse {
    @include responsive-padding;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: bold;
    }

    .image-container {
      color: $riseup_black;
      height: 100%;
    }
  }

  .list-with-shadow {
    box-shadow: inset 0 2px 6px -2px rgba(199, 199, 199, 0.5);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>
