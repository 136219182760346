<template>
  <div>
    <spinner v-if="processingInProgress" class="loader" src="/images/onboarding/spinner-big.svg"/>
    <template v-else>
      <div v-if="processingTimeout">{{ readyToScrapeTimoutTitle }}</div>
      <div v-else>{{ readyToScrapeTitle }}</div>
      <div>
        <a v-on:click="tryNow" href="#">
          <template v-if="processingTimeout">{{ readyToScrapeTimoutButtonText }}</template>
          <template v-else>{{ readyToScrapeButtonText }}</template>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Segment from '@/Segment';
import CustomersApi from '@/api/CustomersApi';
import Spinner from '../../../onboarding/Spinner';

export default {
  name: 'PartiallyAuthorizedRetryScrape',
  components: {
    Spinner,
  },
  props: {
    readyToScrapeTitle: { type: String },
    readyToScrapeButtonText: { type: String },
    readyToScrapeTimoutTitle: { type: String },
    readyToScrapeTimoutButtonText: { type: String },
    closeModelIfDoneFunc: { type: Function, required: false },
  },
  data() {
    return {
      processingInProgress: false,
      processingTimeout: false,
      timeout: null,
      checkInterval: null,
    };
  },
  methods: {
    async tryNow() {
      Segment.trackUserGot('ObkPartiallyAuthorized_TryNowClicked', { query: this.$route.query });
      this.processingInProgress = true;
      if (this.closeModelIfDoneFunc) {
        this.checkInterval = setInterval(this.closeModelIfDoneFunc, 2000);
      }
      this.timeout = setTimeout(this.giveUpWaiting, 30000);
      await CustomersApi.customerTriggeredScrape().catch(() => {});
    },
    giveUpWaiting() {
      this.processingTimeout = true;
      this.processingInProgress = false;
      clearInterval(this.checkInterval);
    },
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>

<style lang="scss">
.loader img.spinner {
  width: 25px;
  height: 25px;
}
</style>
