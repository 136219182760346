<template>
  <div class="none-connected-credit-card-source-card">
    <div class="top-container">
      <source-logo :source-name="sourceName" class="logo-container"/>
      <div class="button-container">
        <riseup-button class="connect-credit-card-button" :action="connectCreditCard" :title="'לחיבור חשבון האשראי'" :size="'slim'"/>
      </div>
    </div>
    <separator/>
    <expandable-credit-card-expense-list
        :title="'חיובים מחברת האשראי'"
        :credit-card-expenses="creditCardExpenses"
    />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SourceLogo from '@/base-components/onboarding/SourceLogo';
import ExpandableCreditCardExpenseList from '@/base-components/onboarding/ExpandableCreditCardExpenseList';

export default {
  name: 'NonConnectedCreditCardCardSourceCard',
  components: {
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
    ExpandableCreditCardExpenseList,
    SourceLogo,
  },
  props: {
    sourceName: {
      type: String,
      required: true,
    },
    creditCardExpenses: {
      type: Array,
      required: true,
    },
    connectCreditCard: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .none-connected-credit-card-source-card {
    border: solid 1px $riseup_gray_0;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(148, 148, 148, 0.4);
    width: 100%;
    background-color: $riseup_white;

    .top-container {
      @include responsive-padding-top-bottom;
      @include responsive-padding;

      .logo-container {
        margin-bottom: 50px;
      }

      .button-container {
        .connect-credit-card-button {
          width: 100%;
        }
      }
    }

  }
</style>
