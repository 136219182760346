<template>
  <banner-page title="מה הן ההכנסות הקבועות החודשיות שלך?"
               :background-position="banner.backgroundPosition"
               :background-color="banner.backgroundColor"
               imgSrc="/images/onboarding/ob-income-investigation.svg">
    <sticky-cta-page :pageBackgroundColor="backgroundColor" :ctaProps="ctaProps" @click="doneAnswering">
      <div class="income-investigation-onboarding">
        <expandable-transaction-check-list v-if="hasPredictedQuestions"
                                           :is-expandable="false"
                                           :transaction-list="hasPredictedQuestions ? predictedIncomeQuestionsList : actualIncomeQuestionsList"
                                           :toggleCheck="togglePredictedIsFixed">
          <template v-slot:subtitle>
            <div class="subtitle ri-large-body margin-bottom-l">
              המערכת צופה שההכנסות האלו
              <span class="ri-large-bold-body"> יכנסו כל חודש בסכום די זהה. </span>
              אם זיהית פה הכנסה חד פעמית, אפשר להסיר אותה:
            </div>
          </template>
        </expandable-transaction-check-list>

        <expandable-transaction-check-list v-if="hasActualQuestions"
                                           :title="varibaleTransactionsTitle"
                                           :is-expandable="hasPredictedQuestions"
                                           color-variant="green"
                                           :transaction-list="actualIncomeQuestionsList"
                                           :toggleCheck="toggleActualIsFixed">
          <template v-if="!hasPredictedQuestions" v-slot:subtitle>
            <div class="subtitle ri-large-body margin-bottom-l">
              המערכת זיהתה רק הכנסות שלא הולכות לחזור בחודשים הבאים. אולי פיספסנו ו
              <span class="ri-large-bold-body">יש כאן הכנסה קבועה?</span>
              אם כן, אפשר לסמן אותה.
            </div>
          </template>
        </expandable-transaction-check-list>
      </div>
    </sticky-cta-page>
  </banner-page>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import consts from '@/constants/onboarding-state';
import ExpandableTransactionCheckList from '@/base-components/onboarding/ExpandableTransactionCheckList.vue';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'IncomeInvestigationOnboarding',
  components: {
    ExpandableTransactionCheckList,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      banner: {
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
      },
      backgroundColor: BaseUI.Colors.riseupWhite,
      ctaProps: {
        buttonProps: {
          loading: false,
          title: 'סיימתי, אפשר להמשיך',
        },
        backgroundColor: BaseUI.Colors.riseupWhite,
      },
    };
  },
  async created() {
    this.ctaProps.buttonProps.loading = true;
    await this.fetchIncomeQuestions();
    const numberOfFixedTransactions = this.predictedIncomeQuestions.filter(question => question.isFixed).length;
    const numberOfVariableTransactions = this.actualIncomeQuestions.length;
    Segment.trackUserGot('FixedIncomeReviewEntered', {
      newBrand: true, numberOfFixedTransactions, numberOfVariableTransactions, buildsFixedIncomeUsingMLModel: this.buildsFixedIncomeUsingMLModel,
    });
    Segment.trackUserGot('FixedIncomeReviewEntered_Marketing', { newBrand: true });
    this.ctaProps.buttonProps.loading = false;
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapGetters('incomeInvestigationQuestions', ['fixedIncomes', 'canceledIncomes', 'incomeInputs', 'numOfFixed']),
    ...mapGetters('featureFlags', ['buildsFixedIncomeUsingMLModel']),
    ...mapState('incomeInvestigationQuestions', ['actualIncomeQuestions', 'predictedIncomeQuestions']),
    ...mapState('onboardingState', ['isLoading']),
    currentPageName() {
      return consts.ONBOARDING_PAGE.INCOME_INVESTIGATION;
    },
    predictedIncomeQuestionsList() {
      return _.chain(this.predictedIncomeQuestions)
        .filter(question => question.incomeAmount > 0)
        .map(question => {
          return {
            businessName: question.businessName,
            amount: question.incomeAmount,
            isChecked: question.isFixed,
            envelopeId: question.envelopeId,
          };
        }).value();
    },
    actualIncomeQuestionsList() {
      return _.map(this.actualIncomeQuestions, question => {
        return {
          businessName: question.businessName,
          amount: question.incomeAmount,
          isChecked: question.isFixed,
          transactionId: question.transactionId,
        };
      });
    },
    hasPredictedQuestions() {
      return !_.isEmpty(this.predictedIncomeQuestions);
    },
    hasActualQuestions() {
      return !_.isEmpty(this.actualIncomeQuestions);
    },
    varibaleTransactionsTitle() {
      return this.hasPredictedQuestions
        ? 'אולי פספסנו משהו?'
        : 'לא זיהינו הכנסות קבועות בחשבון';
    },
  },
  methods: {
    ...mapActions('incomeInvestigationQuestions', ['fetchIncomeQuestions']),
    ...mapActions('onboardingState', ['incomeInvestigationSubmit']),
    ...mapMutations('incomeInvestigationQuestions', ['setPredictedIsFixed', 'setActualIsFixed']),
    async doneAnswering() {
      this.ctaProps.buttonProps.loading = true;
      const numberOfRemovedFixed = this.canceledIncomes.length;
      const numberOfAddedVariables = this.fixedIncomes.length;
      const totalFixed = this.numOfFixed;
      Segment.trackUserInteraction('FixedIncomeReviewNextClicked', {
        newBrand: true, numberOfRemovedFixed, numberOfAddedVariables, totalFixed, buildsFixedIncomeUsingMLModel: this.buildsFixedIncomeUsingMLModel,
      });
      Segment.trackUserInteraction('FixedIncomeReviewNextClicked_Marketing', { newBrand: true });
      await this.incomeInvestigationSubmit(this.incomeInputs);
      this.ctaProps.buttonProps.loading = false;
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    togglePredictedIsFixed(question) {
      const isChecked = !question.isChecked;
      this.setPredictedIsFixed({ envelopeId: question.envelopeId, isFixed: isChecked });
    },
    toggleActualIsFixed(question) {
      const isChecked = !question.isChecked;
      this.setActualIsFixed({ transactionId: question.transactionId, isFixed: isChecked });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.income-investigation-onboarding {
  @include white-page-frame;
  height: 100%;
  width: 100%;

  .subtitle {
    color: $riseup_dark_green;
  }
}
</style>
