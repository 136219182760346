<template>
  <div class="credit-card-expenses">
    <div v-for="(creditCardExpense, index) in creditCardExpenses" :key="index" class="expense-row">
      <div class="row-content">
        <div class="amount">
          <span>{{ formatAmount(creditCardExpense.billingAmount) }}</span>
          <span>₪</span>
        </div>
        <div class="billing-date">{{ formatBillingDate(creditCardExpense.billingDate) }}</div>
      </div>
      <separator v-if="index != creditCardExpenses.length -1"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '../../utils/money';

export default {
  name: 'CreditCardExpensesList',
  components: {
    Separator: BaseUI.Separator,
  },
  props: {
    creditCardExpenses: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    formatBillingDate(billingDate) {
      return moment(billingDate).format('D.M.YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.credit-card-expenses {
  background-color: $riseup_gray_-1;

  .expense-row {
    .row-content {
      @include responsive-padding;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .billing-date {
        font-size: 14px;
      }
      .amount {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .separator-wrapper {
      padding: 0;
    }
  }
}

</style>
