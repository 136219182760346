<template>
  <div class="credentials-list" v-if="!isLoading">
    <div v-if="!enableCredentialsActions" class="obk-disabled-source">
      <div class="obk-disabled-source-content">
        <div class="icon"><img :src="require('@/assets/icons/notice-gray.svg')" class="image"/></div>
        <div class="text">{{ disabledInfoText }}</div>
      </div>
    </div>
    <template v-if="(invalidCredentialsList.length) > 0">
      <div class="creds-card-wrapper" v-for="(source, index) in invalidCredentialsList" :key="source.credentialsId">
        <open-banking-renewal-credentials-card v-if="isOpenBanking(source)" :source="source"/>
        <invalid-crednetials-card v-else :key="index"
                                  class="account-wrapper"
                                  :source="source"
                                  :migration-from-passwords="migrationFromPasswordsEnabled(source)"
                                  @update-credentials="() => openUpdateCredentialsFlow(source)"
        />
      </div>
    </template>

    <template v-if="connectedCredentials.length > 0">
      <connected-credentials-card v-for="source in bankAccounts" :key="source.credentialsId"
                                  class="account-wrapper"
                                  :source="source"
                                  :source-type="source.type"
                                  :name="source.name"
                                  :sourceName="source.sourceName"
                                  :status="source.status"
                                  :accounts="source.accounts">
        <template v-slot:buttons>
          <partially-authorized-retry-scrape-button v-if="source.status === 'partiallyAuthorized'"/>
          <riseup-button v-if="canMigrateToOBK(source)"
                         class="migrate-obk-btn"
                         :action="() => migrateToOBK(source)" size="slim" title="להחליף לחיבור ישיר" variant="secondary"/>
        </template>
      </connected-credentials-card>
      <separator class="accounts-separator" v-if="creditCardAccounts.length > 0"/>
      <connected-credentials-card v-for="source in creditCardAccounts" :key="source.credentialsId"
                                  class="account-wrapper"
                                  :source="source"
                                  :source-type="source.type"
                                  :name="source.name"
                                  :sourceName="source.sourceName"
                                  :status="source.status"
                                  :accounts="source.accounts">
        <template v-slot:buttons>
          <riseup-button v-if="canMigrateToOBK(source)"
                         class="migrate-obk-btn"
                         :action="() => migrateToOBK(source)" size="slim" title="להחליף לחיבור ישיר" variant="secondary"/>
        </template>
      </connected-credentials-card>
    </template>
    <div>
      <separator class="separator"/>
      <add-credentials-card
        :add-new-credentials="openCreateCredentialsFlow"
        :enabled="enableCredentialsActions"
        class="new-account-wrapper"
        title="להוספת חשבון בנק או אשראי"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import PartiallyAuthorizedRetryScrapeButton
  from '@/base-components/credentials/credentials-card/open-banking/PartiallyAuthorizedRetryScrapeButton';
import OpenBankingCelebrationPopup
  from '@/pages/responsive-pages/non-authenticated/open-banking/OpenBankingCelebrationPopup';
import BankCCWarningPopup from '@/base-components/credentials/credentials-list/BankCCWarningPopup';
import Segment from '@/Segment';
import OpenBankingMixin from '@/base-components/onboarding/openBanking/OpenBankingMixin';
import OpenBankingRenewalCredentialsCard from '../credentials-card/open-banking/OpenBankingRenewalCredentialsCard.vue';
import ConnectedCredentialsCard from '../../onboarding/ConnectedCredentialsCard';
import AddCredentialsCard from '../../onboarding/AddCredentialsCard';
import AddCredentialsFlow
  from '../../../pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import InvalidCrednetialsCard from './InvalidCrednetialsCard';

export default {
  name: 'CredentialsList',
  components: {
    InvalidCrednetialsCard,
    ConnectedCredentialsCard,
    AddCredentialsCard,
    PartiallyAuthorizedRetryScrapeButton,
    OpenBankingRenewalCredentialsCard,
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
  },
  mixins: [OpenBankingMixin],
  async created() {
    this.isLoading = false;
    const { sourceIdToUpdate } = this.$route.query;
    if (sourceIdToUpdate) {
      const source = _.find(this.credentialsList, { backendName: sourceIdToUpdate });
      this.directMigrationFlow(source);
    }
  },
  data() {
    return {
      disabledOBKSources: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapState('credentials', ['credentialsList']),
    ...mapGetters('credentials', ['invalidCredentialsList', 'connectedCredentials', 'isAllOBKSourcesValid',
      'getDisabledOBKSources', 'validCredentialsList', 'unvalidatedCredentialsList',
      'partiallyAuthorizedCredentialsList', 'getCredsBankCCAccounts', 'enableCredentialsActions', 'disabledSourceName']),
    ...mapGetters('featureFlags', ['migrationFromPasswords']),
    disabledInfoText() {
      if (!this.isAllOBKSourcesValid) {
        return ` בגלל התקלה ב${this.disabledSourceName}, כרגע אי אפשר לעשות פעולות שקשורות לחשבונות המחוברים לתזרים.`;
      }
      return 'כל עוד יש בתזרים חשבונות לא מחוברים, אי אפשר לעשות פעולות על החשבונות האחרים או להוסיף חשבונות חדשים.';
    },
    bankAccounts() {
      return _.filter(this.connectedCredentials, c => c.type === 'bank');
    },
    creditCardAccounts() {
      return _.filter(this.connectedCredentials, c => c.type === 'card');
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    canMigrateToOBK(credentials) {
      if (!this.enableCredentialsActions) {
        return false;
      }
      return this.shouldShowObkConnectOption(credentials);
    },
    isOpenBanking(source) {
      return !!source.openBankingConsentId;
    },
    openCreateCredentialsFlow() {
      this.openModal({
        component: AddCredentialsFlow,
        popupAlignment: 'full-screen',
      });
    },
    directMigrationFlow(source) {
      Segment.trackUserInteraction('UpdateCredsFromLink_Entered', {
        sourceName: source.sourceName,
      });
      this.openBankingPopup(source);
    },
    openUpdateCredentialsFlow(source) {
      Segment.trackUserInteraction('UpdateInvalidCreds_Clicked', {
        sourceName: source.sourceName,
        migrationFromPasswords: this.isAllOBKSourcesValid,
      });
      if (this.isAllOBKSourcesValid) {
        this.connectWithOBK(source);
      } else {
        this.openModal({
          component: AddCredentialsFlow,
          props: {
            sourceToUpdate: source,
            updateMode: true,
          },
          popupAlignment: 'full-screen',
        });
      }
    },
    migrateToOBK(source) {
      Segment.trackUserInteraction('MigrateToOBKClicked', { sourceName: source.sourceName });
      this.connectWithOBK(source);
    },
    connectWithOBK(source) {
      this.openModal({
        component: OpenBankingCelebrationPopup,
        props: {
          source,
          action: () => {
            this.openBankingPopup(source);
          },
        },
      });
    },
    openBankingPopup(source) {
      const bankCCAccounts = this.getCredsBankCCAccounts(source.credentialsId);
      if (bankCCAccounts?.length) {
        this.openBankCCWarningPopup(source, bankCCAccounts);
      } else {
        this.openOBKConnectionFlow(source);
      }
    },
    openBankCCWarningPopup(source, bankCCAccounts) {
      this.openModal({
        component: BankCCWarningPopup,
        props: {
          source,
          bankCCAccounts,
          openOBKConnectionFlow: () => this.openOBKConnectionFlow(source),
        },
      });
    },
    openOBKConnectionFlow(source) {
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: source.sourceName,
          closedBankingCredentialsId: source.credentialsId,
          preventLoadingCredentialsCheck: true,
        },
        popupAlignment: 'full-screen',
      });
    },
    migrationFromPasswordsEnabled(source) {
      return !this.isOpenBanking(source) && this.isAllOBKSourcesValid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.credentials-list {
  @include responsive-padding;
  width: 100%;
  padding-top: 25px;

  .obk-disabled-source {
    .obk-disabled-source-content {
      display: flex;
      flex-direction: row;
      padding-bottom: 25px;

      .icon {
        margin: 1px 0 0 4px;
        .image {
          height: 27px;
        }
      }

      .text {
        line-height: 18px;
        font-family: SimplerPro;
        font-size: 12px;
        color: $riseup_gray_2;
        margin-right: 15px;

        .bold {
          font-weight: bold;
        }
      }
    }
  }

  .title {
    @include title;
    margin-bottom: 10px;
  }

  .separator {
    margin-top: 40px;
  }

  .accounts-separator {
    margin-top: 0;
  }

  .accounts-separator, .account-wrapper {
    margin-bottom: 24px;
  }

  .add-new, .creds-card-wrapper {
    margin-bottom: 16px;
  }

  .new-account-wrapper {
    margin: 40px 0;
  }

  .migrate-obk-btn{
    margin-top: 30px;
  }
}
</style>
