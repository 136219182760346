<template>
  <div :class="{ disabled: !enabled }" class="add-credentials-card" v-on:click="enabled && addNewCredentials()">
    <img class="plus-image" src="/images/onboarding/icons/icon-add.svg">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>

export default {
  name: 'addCredentialsCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    addNewCredentials: {
      type: Function,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/border-radius';
  @import '../../scss/mixins';

  .add-credentials-card {
    height: 185px;
    padding: 30px 0;
    border: dashed 1px $riseup_blue;
    border-radius: $card-border-radius;
    width: 100%;
    background-color: $riseup_white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    .plus-image {
      height: 47px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: $riseup_blue;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: default;
  }
</style>
