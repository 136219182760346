<template>
  <simple-image-page
    page-label="הכנו תכנית מיוחדת לזוגות"
     :img-src="require('@/assets/money-moon/money-moon-join-header.svg')"
     title="לפתוח פרק חדש ביחסים עם כסף"
     :button-props="buttonProps">
    <template v-slot:textSlot>
      <div>
       <div>
         תוך שלושה דייטים בשבועיים הקרובים, נגלה איך להקליל את השיח על כסף ונבין איך
         <span class="bold">להגשים את החלומות המשותפים שלנו</span>
         .
       </div>
        <div class="free">
          ההצטרפות לתכנית חינם ללקוחות רייזאפ.
        </div>
      </div>
    </template>
  </simple-image-page>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import SimpleImagePage from '@/base-components/SimpleImagePage';
import MoneyMoonJoinModal from '@/pages/responsive-pages/authenticated/navigation-pages/money-moon/MoneyMoonJoinModal';

export default {
  name: 'MoneyMoonJoinPage',
  components: {
    SimpleImagePage,
  },
  created() {
    Segment.trackUserInteraction('MoneyMoonJoin_Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('MoneyMoonJoin_Clicked');
      this.openModal({ component: MoneyMoonJoinModal });
    },
  },
  computed: {
    buttonProps() {
      return {
        title: 'אני רוצה להצטרף לתכנית!',
        action: this.buttonAction,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.free {
  padding-top: 22px;
}
</style>
