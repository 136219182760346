<template>
  <simple-image-page
    page-label="הטבה למועדון חבר"
     :img-src="require('@/assets/hever/header_hever.svg')"
     title=""
     :button-props="buttonProps">
    <template v-slot:textSlot>
      <div id="hever-content">
        <div class="ri-font-weight-bold ri-large-headline">לעמיתי מועדון חֶבֶר מגיעה הטבה על מנוי RiseUp:<br/> 50% הנחה ל־6 חודשים!</div>
        <div class="ri-font-weight-regular ri-body margin-vertical-m">למימוש ההטבה צריך להתחבר לאתר חבר ולהשלים את התהליך.</div>
        <div class="ri-font-weight-regular ri-small-body">*הטבה ללא כפל מבצעים ותחליף כל הטבה אחרת במידה וקיימת.</div>
      </div>
    </template>
  </simple-image-page>
</template>

<script>
import Segment from '@/Segment';
import SimpleImagePage from '@/base-components/SimpleImagePage';

export default {
  name: 'HeverBenefitPage',
  components: {
    SimpleImagePage,
  },
  created() {
    Segment.trackUserInteraction('HeverBenefitPage_Entered');
  },
  methods: {
    buttonAction() {
      Segment.trackUserInteraction('HeverBenefitButton_Clicked');
      window.location.href = 'https://www.mcc.co.il/site/pg/mcc_item_new,329116';
    },
  },
  computed: {
    buttonProps() {
      return {
        title: 'למימוש ההטבה באתר חֶבֶר',
        action: this.buttonAction,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
