<template>
  <banner-page title="המערכת זיהתה סכום גבוה במיוחד שנכנס החודש?"
               :background-position="banner.backgroundPosition"
               :background-color="banner.backgroundColor"
               imgSrc="/images/onboarding/ob-income-investigation.svg">
    <sticky-cta-page :pageBackgroundColor="ctaBackgroundColor" :ctaProps="ctaProps" @click="doneAnswering">
      <div class="income-exclusion-onboarding">
        <div class="page-content">
          <expandable-transaction-check-list
            :is-expandable="false"
            :transaction-list=" highIncomeExcludedTransactions"
            :toggleCheck="toggleTransactionIsIncluded">
            <template v-slot:subtitle>
              <div class="subtitle">
                מומלץ לסמן את ההכנסה ולהוסיף אותה לתזרים <span class="bold"> רק אם היא תשמש אותך כדי לממן את ההוצאות של {{ monthName }}:</span>
              </div>
            </template>
          </expandable-transaction-check-list>
          <div v-if="includedIncomes.length" class="second-subtitle">
            <span class="bold">ההכנסה הזאת תופיע בהכנסות של {{ monthName }}.</span>
          </div>
          <div v-else class="second-subtitle">
            <span class="bold"> ההכנסה הזאת תופיע ב״הכנסות לא תזרימיות״ </span>
            <span>ותמיד אפשר להעביר אותה משם להכנסות.</span>
          </div>
        </div>
      </div>
    </sticky-cta-page>
  </banner-page>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import cashflowHistory from '@/store/utilities/cashflow-history';
import moneyUtils from '@/utils/money';
import consts from '@/constants/onboarding-state';
import ExpandableTransactionCheckList from '@/base-components/onboarding/ExpandableTransactionCheckList.vue';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'IncomeExclusionOnboarding',
  components: {
    ExpandableTransactionCheckList,
    BannerPage: BaseUI.Pages.BannerPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      // This property is important for the ExpandableTransactionCheckList
      // component to be rendered each time we toggle the transaction (it makes the highIncomeExcludedTransactions to be updated)
      includedIncomes: [],
      ctaBackgroundColor: BaseUI.Colors.riseupWhite,
      loading: false,
      banner: {
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
      },
    };
  },
  async created() {
    this.loading = true;
    await this.initBudgets({ budgetDate: cashflowHistory.getCurrentBudgetDate() });
    Segment.trackUserGot('HighIncomeExclusionEntered', {
      newBrand: true, numberOfExcludedTransactions: this.highIncomeExcludedTransactions.length,
    });
    Segment.trackUserGot('HighIncomeExclusionEntered_Marketing', {
      newBrand: true, numberOfExcludedTransactions: this.highIncomeExcludedTransactions.length,
    });
    this.loading = false;
  },
  computed: {
    ...mapGetters('cashflowView', ['excluded']),
    ...mapState('onboardingState', ['isLoading']),
    ctaProps() {
      return {
        buttonProps: {
          title: 'אפשר להמשיך',
          loading: this.loading || this.isLoading,
        },
        backgroundColor: BaseUI.Colors.riseupWhite,
      };
    },
    currentPageName() {
      return consts.ONBOARDING_PAGE.INCOME_EXCLUSION;
    },
    highIncomeExcludedTransactions() {
      return _.chain(this.excluded)
        .filter({ excludeReason: 'highIncomeExclusion' })
        .map(transaction => {
          return {
            ...transaction,
            amount: transaction.incomeAmount,
            isChecked: _.includes(this.includedIncomes, transaction.transactionId),
          };
        })
        .value();
    },
    monthName() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getCurrentBudgetDate());
    },
  },
  methods: {
    ...mapActions('onboardingState', ['highIncomeExclusionSubmit']),
    ...mapActions('cashflowView', ['initBudgets']),
    async doneAnswering() {
      this.loading = true;
      Segment.trackUserInteraction('HighIncomeExclusionNextClicked', {
        newBrand: true,
        numberOfExcludedTransactions: this.highIncomeExcludedTransactions.length,
        numberOfIncludedTransactions: this.includedIncomes.length,
      });
      await this.highIncomeExclusionSubmit(this.includedIncomes);
      this.loading = false;
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    toggleTransactionIsIncluded(income) {
      if (!income.isChecked) {
        this.includedIncomes.push(income.transactionId);
      } else {
        this.includedIncomes = _.without(this.includedIncomes, income.transactionId);
      }
      Vue.set(income, 'isChecked', !income.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
//noinspection CssUnknownTarget
@import '@/scss/mixins';

.income-exclusion-onboarding {
  @include white-page-frame;
  height: 100%;

  .page-content {
    height: 100%;
    width: 100%;
  }

  .subtitle {
    font-size: 18px;
    color: $riseup_black;
    margin-bottom: 40px;
    line-height: 1.33;

    .bold {
      font-weight: bold;
    }
  }

  .second-subtitle {
    @extend .subtitle;
    margin: 30px 25px 0 25px;
  }
}
</style>
