<template>
  <sticky-cta-page :pageBackgroundColor="ctaProps.backgroundColor" :ctaProps="ctaProps" @click="next">
    <div class="security-page flex-column flex-1" :style="{ backgroundColor }">
      <div class="security-info-title ri-large-headline ri-alte">
        כדי לבנות לך תזרים חודשי המערכת צריכה גישה מאובטחת לחשבונות הבנק והאשראי שלך
      </div>
      <div class="security-info-content ri-large-body flex-column flex-1 padding-horizontal-ml">
        <div class="flex-1 flex-column justify-content-center margin-bottom-xl margin-top-m">
          <img src="/images/onboarding/ob-security-info.png">
        </div>
        <div class="flex-column flex-1 align-center padding-bottom-xxl" v-for="(content, index) in securityBullets" :key="index">
          <colored-svg svgSrc="/images/shapes/ten-pointed-star.svg" :color="starColor" class="star-icon"/>
          <div class="ri-bold-body margin-bottom-s">{{content.line1}}</div>
          <div class="ri-medium-headline">{{content.line2}}</div>
          <div class="ri-medium-headline">{{content.line3}}</div>
        </div>
        <div>
          <div class="ri-bold-body margin-bottom-l flex-row justify-content-center">אנחנו עושים הכל כדי לשמור על המידע שלך</div>
          <img src="/images/onboarding/ob-security-trust-logos.svg" class="full-width">
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SecurityInformation',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    ColoredSvg: BaseUI.ColoredSvg,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupLightYellow,
      ctaProps: {
        buttonProps: {
          loading: false,
          title: 'לחיבור החשבונות',
        },
        backgroundColor: BaseUI.Colors.riseupLightYellow,
      },
      starColor: BaseUI.Colors.riseupBlue,
      securityBullets: [
        { line1: 'למערכת יש גישה לקריאת הנתונים בלבד', line2: 'לא נבצע שום פעולות', line3: 'בחשבון הבנק שלך' },
        { line1: 'הכסף שלך מוגן בכל מצב ', line2: 'יש לך איתנו ביטוח אחריות', line3: 'מקצועית של חברת AIG' },
        { line1: 'אנחנו מאגר מידע רשום', line2: 'בפיקוח הרשות לניירות ערך', line3: 'והרשות להגנת הפרטיות' },
        { line1: 'לא נבקש פרטי אשראי מלאים ותעודת זהות', line2: 'למערכת אין יכולת לבצע', line3: 'פעולות בשמך' },
      ],
    };
  },
  async created() {
    Segment.trackUserGot('SecurityPage_PageEntered', { newBrand: true });
    Segment.trackUserGot('SecurityPage_PageEntered_Marketing', { newBrand: true });
  },
  methods: {
    ...mapActions('onboardingState', ['securityInformationSubmit']),
    async next() {
      this.ctaProps.buttonProps.loading = true;
      Segment.trackUserInteraction('SecurityPage_NextClicked', { newBrand: true });
      Segment.trackUserInteraction('SecurityPage_NextClicked_Marketing', { newBrand: true });
      await this.securityInformationSubmit();
      this.ctaProps.buttonProps.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.security-info-title {
  color: $riseup_dark_green;
  padding: $ri-spacing-ml $ri-spacing-ml $ri-spacing-sm $ri-spacing-ml;
}

.security-info-content {
  color: $riseup_dark_green;
}

.star-icon {
  height: 30px;
  width: 30px;
  margin-bottom: $ri-spacing-xs;
}
</style>
