<template>
  <div>
    <div class="flex-column flex-1 justify-content-center align-center margin-bottom-l margin-top-l">
      <img style="width: 80%;" :src="require('./riseup-pool-header.svg')"/>
    </div>
    <div v-if="!isLoading" class="padding-horizontal-l margin-bottom-xxl">
      <tabbed-section :tabs="tabs" @tab-click="tabClicked">
        <div v-if="selectedTabKey === RAW_TAB" class="margin-top-m">
          <riseup-pool-raw :riseupPool="riseupPool"
                           :savingTransactionIdsFromHamster="savingTransactionIdsFromHamster"
                           :refreshRiseupPool="refreshRiseupPool"
                           :excludeTransactionsFromPool="excludeTransactionsFromPool" />
        </div>
        <div v-else-if="selectedTabKey === PRETTY_TAB" class="margin-top-m">
          <riseup-pool-pretty :riseupPool="riseupPool"
                              :savingTransactionIdsFromHamster="savingTransactionIdsFromHamster"
 />
        </div>
      </tabbed-section>
    </div>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import * as HamsterApi from '@/api/HamsterApi';
import RiseupPoolRaw from './RiseupPoolRaw.vue';
import RiseupPoolPretty from './RiseupPoolPretty';

const RAW_TAB = 'raw';
const PRETTY_TAB = 'pretty';

export default {
  name: 'RiseupPool',
  components: {
    TabbedSection: BaseUI.TabbedSection,
    RiseupPoolRaw,
    RiseupPoolPretty,
  },

  data() {
    return {
      isLoading: true,
      RAW_TAB,
      PRETTY_TAB,
      selectedTabKey: PRETTY_TAB,
      savingTransactionIdsFromHamster: [],
      riseupPool: [],
    };
  },
  async created() {
    this.savingTransactionIdsFromHamster = (await HamsterApi.getCustomerSavingTransactions())
      .map(item => item.transactionId);
    this.riseupPool = (await HamsterApi.getRiseupPool());
    this.isLoading = false;
  },

  computed: {
    tabs() {
      return [
        {
          key: PRETTY_TAB,
          text: 'הצבירה',
        },
        {
          key: RAW_TAB,
          text: 'הדאטא',
        },
      ];
    },
  },
  methods: {
    tabClicked(key) {
      this.selectedTabKey = key;
    },
    async refreshRiseupPool() {
      this.riseupPool = await HamsterApi.getRiseupPool();
    },
    async excludeTransactionsFromPool(excludedFromPoolTransactionIds) {
      await HamsterApi.excludeTransactionsFromPool(excludedFromPoolTransactionIds);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

</style>
