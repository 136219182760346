<template>
  <server-error v-if="error" />
  <div v-else class="reset-cashflow">
    <div class="information">
      <div class="body">
        <div class="title">
          <div class="rotating-sun">
            <img :src="require('@/assets/sun4.svg')" />
          </div>
          <div class="information-title">
            אנחנו מאוד שמחים שחזרת, ורוצים לספר לך ש:
          </div>
        </div>
        <div class="description">
          <div v-for="bullet in bullets" :key="bullet.icon" class="bullet">
            <div class="bullet-icon">
              <img :src="require(`@/assets/icons/${bullet.icon}.svg`)">
            </div>
            <div class="bullet-text">
              <div class="bullet-title">
                {{ bullet.title }}
              </div>
              {{ bullet.text }}
            </div>
          </div>
        </div>
        <div class="footer">
          <single-click-button :title="buttonTitle" :loading="isLoading" :action="next" :size="'small'"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import DDLogs from '@/DDLogs';
import promotions from '@/constants/promotions';
import * as _ from 'lodash';
import isDormantWinback from '@/utils/dormant-winback';
import ServerError from '@/pages/general-errors/ServerError';
import CustomersApi from '@/api/CustomersApi';
import featureFlags from '@/api/featureFlag';
import Segment from '../../../Segment';
import SingleClickButton from '../../../base-components/SingleClickButton';

const FREE_TRIAL_VARIANT = 'trial';
const DISCOUNT_VARIANT = 'discount';
const PROMO_CODE = 'dormant-winback';

export default {
  async created() {
    this.variant = await featureFlags.getTreatment('dormant-winback');
    if (this.variant === DISCOUNT_VARIANT) {
      await this.fetchPromotion(PROMO_CODE);
    }
    Segment.trackUserGot('DormantWinbackWelcomePageEntered', { variant: this.variant });
    if (this.$route.query.promoCode) {
      const { promoCode } = this.$route.query;
      await this.fetchPromotion(promoCode);
      await this.createBenefit(promoCode);
      Segment.trackUserGot('PromotionForDormant', { promoCode });
    }
    const shouldShowDormantWinback = await isDormantWinback();
    if (!shouldShowDormantWinback) {
      // Will be called if the customer clicks "back"
      Segment.trackUserInteraction('DormantWinbackWelcomePageEnteredByMistake', { variant: this.variant });
      await router.push({ path: '/sr' });
    }
  },
  data() {
    return {
      error: false,
      variant: 'off',
      promoCode: null,
      isLoading: false,
    };
  },
  components: {
    ServerError,
    SingleClickButton,
  },
  name: 'ResetCashflow',
  computed: {
    ...mapGetters('session', ['activeMemberFirstName']),
    ...mapState('promotions', ['benefitAmount', 'benefitPercentage', 'benefitType']),
    ...mapGetters('subscriptionState', ['isDormantWithoutMGB']),
    ...mapState('websocket', ['socketMessage']),
    bullets() {
      return [
        ...this.promoDetails,
        {
          title: 'שיפרנו את תהליך חיבור החשבונות',
          text: 'חיבור קל ומהיר בלי סיסמאות',
          icon: 'dome-check-mark',
        },
        {
          title: 'חידשנו המון',
          text: 'פיצול הוצאות, חיסכון דינמי ועוד',
          icon: 'star-check-mark',
        },
        ...this.extraBenefits,
      ];
    },
    promoDetails() {
      if (this.variant === FREE_TRIAL_VARIANT) {
        return [{
          title: 'מגיע לך חודש נסיון במתנה!',
          text: 'ולאחר מכן, 45 ₪ לחודש',
          icon: 'clover-check-mark',
        }];
      }
      if (this.variant === DISCOUNT_VARIANT) {
        return [{
          title: 'מגיעה לך הנחה על דמי המינוי!',
          text: this.getPromoText(),
          icon: 'clover-check-mark',
        }];
      }
      return [];
    },
    extraBenefits() {
      if (!_.includes([FREE_TRIAL_VARIANT, DISCOUNT_VARIANT], this.variant)) {
        return [{
          title: 'אפשר לראות תזרימים קודמים',
          text: 'ללמוד על מה היה ולצפות מה יהיה',
          icon: 'clover-check-mark',
        }];
      }
      return [];
    },
    buttonTitle() {
      if (this.isDormantWithoutMGB) {
        return 'לבחירת סוג מינוי';
      }
      if (!_.includes([FREE_TRIAL_VARIANT, DISCOUNT_VARIANT], this.variant)) {
        return 'להמשיך לתשלום';
      }
      return 'להתחבר מחדש לשירות';
    },
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    ...mapActions('promotions', ['fetchPromotion']),
    ...mapActions('benefits', ['createBenefit']),
    async next() {
      Segment.trackUserInteraction('DormantWinbackWelcomePageClickedNext', { variant: this.variant });
      try {
        DDLogs.log('Resetting cashflow from reset-cashflow page');
        this.isLoading = true;
        const promoCode = this.getPromo();
        await CustomersApi.resetForDormant(promoCode);
        DDLogs.log('Waiting for version in reset-cashflow page');
      } catch (error) {
        DDLogs.error('Failed resetting cashflow for dormant', { error });
        this.error = true;
      }
    },
    getPromo() {
      if (this.variant === 'trial') {
        return 'trial';
      }
      if (this.variant === 'discount') {
        return PROMO_CODE;
      }
      return undefined;
    },
    getPromoText() {
      if (this.benefitType === promotions.BENEFIT_TYPES.PERCENTAGE_DISCOUNT) {
        return `${this.benefitAmount} חודשים ב־${this.benefitPercentage}% הנחה (${this.priceAfterDiscount()} ₪)`;
      }
      return `${this.benefitAmount} חודשים חינם`;
    },
    priceAfterDiscount() {
      return 45 - Math.round(45 * (this.benefitPercentage / 10)) / 10;
    },
  },
  watch: {
    socketMessage(newValue, oldValue) {
      if (this.isLoading && newValue !== oldValue) {
        DDLogs.log('Done waiting for version in cashflow reset page, moving to next page');
        window.location.href = !_.includes([DISCOUNT_VARIANT, FREE_TRIAL_VARIANT], this.variant) ? '/subscription' : '/sr';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

@keyframes spin {
  from {
    transform: rotate(-25deg);
  }
  to {
    transform: rotate(335deg);
  }
}

.reset-cashflow {
  height: 100%;

  .information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .rotating-sun {
      z-index: 1;
      position: absolute;
      top: -80px;
      left: 40px;

      img {
        animation: spin 20s linear infinite;
      }
    }

    .header-image {
      margin: 65px auto 20px;
      width: 284px;
    }

    .body {
      color: $riseup_black;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 26px;
      padding-bottom: 0px;
      text-align: right;
      background: rgb(0,192,112);
      background: linear-gradient(180deg, #00c070 0%, #00c070 205px, white 205px);

      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        padding: 20px 0px 10px;
        white-space: pre-line;
        color: white;
      }

      .description {
        padding: 16px 0px;
        font-size: 18px;
        line-height: 1.3;
        flex-grow: 1;
        color: white;

        .bullet {
          width: 100%;
          margin-bottom: 15px;
          padding: 15px 11px;
          border: 1px solid #DDDDDD;
          border-radius: 15px;
          background: white;
          display: flex;
          flex-direction: row;
          color: black;

          .bullet-icon img {
            width: 48px;
          }

          .bullet-text {
            flex-grow: 1;
            padding: 5px 10px 0 5px;
            font-size: 17px;

            .bullet-title {
              font-weight: bold;
            }
          }
        }
      }

      .bold {
        font-weight: bold;
      }

    }

    lottie-player {
      height: auto;
    }

    .footer {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: center;
      background-color: $riseup_white;
      padding: 30px;

      .riseup-button {
        width: 100%;
        height: 75px;
      }
    }
  }
}

</style>
