<template>
  <div class="promotion-popup">
    <popup :buttons="buttons" :close-action="closeModal">
      <template v-slot:title>
        <div class="promotion-title">
          <img class="logo-image" :src="require('@/assets/promotions/promo-popup-image.svg')">
        </div>
      </template>
      <template v-slot:content>
        <div class="description">
          <benefit-notification-box-v2 :flavor="flavors.signup" />
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import Segment from '../../Segment';

export default {
  name: 'PromotionDuringOnboardingPopup',
  components: {
    BenefitNotificationBoxV2,
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserGot('PromotionDuringOnboarding');
  },
  mixins: [BenefitMixin],
  computed: {
    buttons() {
      return [
        {
          title: 'מצויין, תודה!',
          action: this.closeModal,
          size: 'slim',
        },
      ];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .promotion-popup {
    .promotion-title {
      text-align: center;
      .popup-title {
        font-family: SimplerPro;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
      }

      .logo-image {
        text-align: center;
        height: 106px;
        width: 106px;
        margin: 30px;
      }
    }
    .description {
      font-family: SimplerPro;
      margin-bottom: 50px;
      line-height: 1.38;
      text-align: center;

      .bold {
        font-weight: bold;
      }
    }
  }
</style>
