<template>
  <connection-failure-base class="scrape-retry-failure" :close-action="closeAction" :source="source" :buttons="buttons">
    <template v-slot:title>
      {{title}}
    </template>
    <template v-slot:subtitle>
      <div>זה קורה, האפשרות לחיבור ישיר ב{{ sourceName }} עדיין חדשה, והדרך לא תמיד חלקה.</div>
        <div class="subtitle-line-2">
          <span>תמיד אפשר להוסיף את החשבון עם פרטי ההתחברות שלך ל{{ sourceName }}. ובכל מקרה</span>
          <span class="bold">הצוות שלנו כאן וישמח לעזור לך.</span>
        </div>
    </template>
  </connection-failure-base>
</template>

<script>
import Intercom from '@/Intercom';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import CreateCredentialsFlow
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CreateCredentialsFlow';
import ConnectionFailureBase from './ConnectionFailureBase';

export default {
  name: 'ScrapeFailure',
  components: {
    ConnectionFailureBase,
  },
  props: {
    sourceName: {
      type: String,
      require: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKScrapeRetryFailureShown', { source: this.sourceName });
    Intercom.hideLauncher();
  },
  computed: {
    title() {
      return `לצערנו החיבור ל${this.sourceName} לא הצליח`;
    },
    buttons() {
      return [{
        action: this.connectWithUsernameAndPassword,
        title: 'להתחבר עם פרטי ההתחברות',
        size: 'slim',
      }, {
        action: this.getHelp,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        title: 'אשמח לעזרה מהצוות',
        size: 'slim',
        variant: 'secondary',
        id: 'cx-scrape-retry-failure',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    connectWithUsernameAndPassword() {
      Segment.trackUserGot('OBKScrapeRetryFailure_ConnectWithUsernameAndPasswordClicked', { source: this.sourceName });
      this.openModal({
        component: CreateCredentialsFlow,
        props: { preventOBkConnection: true },
        popupAlignment: 'full-screen',
      });
    },
    getHelp() {
      Segment.trackUserGot('OBKScrapeRetryFailure_CXButtonClicked', { source: this.sourceName });
      Intercom.show();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .scrape-retry-failure {
    .subtitle-line-2 {
      margin-top: 20px;
    }
  }

</style>
