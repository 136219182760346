<template>
  <div class="timeline-items">
    <TimelineItem
      v-for="timelineItem in timelineItems"
      :key="timelineItem.id"
      :title="timelineItem.title"
      :description="timelineItem.description"
      :checked="timelineItem.checked"
      :icon-name="timelineItem.iconName"
    />
  </div>
</template>

<script>
import TimelineItem from './TimelineItem';

export default {
  name: 'TimelineItems',
  components: {
    TimelineItem,
  },
  props: {
    timelineItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';

.timeline-items {
  padding: $ri-spacing-ml $ri-spacing-l 0 $ri-spacing-l;

  // Hide the line of the first item, since its the start of the timeline
  ::v-deep .item:first-child {
    .timeline {
      .line {
        display: none;
      }
    }
  }

  ::v-deep .item:last-child {
    & > * {
      padding-bottom: 0;
    }
  }
}
</style>
