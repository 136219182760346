<template>
  <div class="new-credentials-list" v-if="!isLoading">
    <div v-if="!allowNewConnections" class="obk-disabled-source">
      <div class="obk-disabled-source-content">
        <div class="icon"><img :src="require('@/assets/icons/notice-gray.svg')" class="image"/></div>
        <div class="text">{{ disabledInfoText }}</div>
      </div>
    </div>
     <template v-for="creds of sortedCredentials" >
        <credentials-card :key="creds.credentialsId"
          class="account-wrapper" :source="creds" :enable-menu="true">
          <template v-slot:buttons>
            <div class="margin-top-m margin-bottom-m">
              <riseup-button v-if="creds.buttons.displayReconnectBtn"
                             subtitle="לעדכון פרטים"
                             size="slim"
                             :bold="true"
                             :action="() => updateRegularCreds(creds)"/>
              <riseup-button v-if="creds.buttons.displayMigrateBtn"
                             :action="() => migrateToOBK(creds)"
                             size="slim"
                             :title="creds.isInvalid ? 'לחידוש החיבור' : 'להחליף לחיבור ישיר'"
                             :variant="!creds.isInvalid ? 'secondary' : 'primary'"/>
              <riseup-button v-if="creds.buttons.displayReconnectConsentBtn"
                             :action="() => reconnectConsent(creds)"
                             size="slim"
                             title="לחידוש ההסכמה"
                             :loading="loadingCreds[creds.credentialsId]"
                             :disabled="showUnsupportedDesktop(creds)"
                             :variant="'primary'"/>
              <div v-if="creds.buttons.displayReconnectConsentBtn && showUnsupportedDesktop(creds)" class="unsupported-desktop-notice">
                <base-tool-tip icon-path="/images/info-icon-blue.svg">
                <span class="bold">
                אפשר לחדש רק באפליקצית {{ creds.displayName }} ולכן נצטרך לעבור למכשיר הנייד.
                </span>
                  אפשר להמשיך בקלות בעזרת הלינק שיש לך בוואטסאפ
                </base-tool-tip>
              </div>
            </div>
          </template>
        </credentials-card>
     </template>
    <add-credentials-card
      :add-new-credentials="openCreateCredentialsFlow"
      class="new-account-wrapper"
      :enabled="allowNewConnections"
      title="להוספת חשבון בנק או אשראי"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import CredentialsCard from '@/base-components/onboarding/CredentialsCard';
import BaseUI from '@riseupil/base-ui';
import AddCredentialsCard from '@/base-components/onboarding/AddCredentialsCard';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import MigrateToOBKFlow from '@/base-components/credentials/credentials-list/MigrateToOBKFlow';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';
import Segment from '@/Segment';
import BankCCWarningPopup from '@/base-components/credentials/credentials-list/BankCCWarningPopup.vue';

export default {
  name: 'NewCredentialsList',
  components: {
    CredentialsCard,
    RiseupButton: BaseUI.RiseupButton,
    AddCredentialsCard,
  },
  mixins: [ConsentActionsMixin],
  data() {
    return {
      isLoading: true,
      loadingCreds: {},
    };
  },
  async created() {
    await this.fetchCredentialsSettings();
    const { sourceIdToUpdate } = this.$route.query;
    if (sourceIdToUpdate) {
      const source = _.find(this.credentialsConfigurations, { backendName: sourceIdToUpdate });
      this.directMigrationFlow(source);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters('credentialsNew', ['credentialsConfigurations', 'allowNewConnections', 'disabledObkSources', 'getCredsBankCCAccounts']),
    // Sorting credentials on order to present invalid creds on top of the list and bank creds before credit cards
    sortedCredentials() {
      return _.chain(this.credentialsConfigurations)
        .filter(c => c.status !== 'closedBankingBackupCreds')
        .orderBy([
          o => o.isInvalid,
          o => o.type === 'bank',
        // other cases are implicitly handled
        ], ['desc', 'desc']);
    },
    disabledSourceName() {
      return getSourceConfigurationBySourceName(_.first(this.disabledObkSources)).displayName;
    },
    disabledInfoText() {
      return ` בגלל התקלה ב${this.disabledSourceName}, כרגע אי אפשר לעשות פעולות שקשורות לחשבונות המחוברים לתזרים.`;
    },
    isMobile() {
      const mobileAgents = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return _.some(mobileAgents, toMatchItem => navigator.userAgent.match(toMatchItem));
    },
  },

  methods: {
    ...mapActions('credentialsNew', ['fetchCredentialsSettings']),
    ...mapActions('modalRootStore', ['openModal']),
    openCreateCredentialsFlow() {
      this.openModal({
        component: AddCredentialsFlow,
        popupAlignment: 'full-screen',
      });
    },
    updateRegularCreds(source) {
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceToUpdate: source,
          updateMode: true,
        },
        popupAlignment: 'full-screen',
      });
    },
    migrateToOBK(source) {
      this.openModal({
        component: MigrateToOBKFlow,
        props: {
          source,
        },
      });
    },
    showUnsupportedDesktop(source) {
      return source.openBanking && !source.openBanking.allowDesktop && !this.isMobile;
    },
    reconnectConsent(source) {
      this.loadingCreds = { ...this.loadingCreds, [source.credentialsId]: true };
      this.redirectToGetAccessCode({
        source,
        credentialsToUpdateMetaData: { consentId: source.openBankingConsentId, credentialsId: source.credentialsId },
      });
    },
    directMigrationFlow(source) {
      Segment.trackUserInteraction('UpdateCredsFromLink_Entered', {
        sourceName: source.sourceName,
      });
      this.openBankingPopup(source);
    },
    openBankingPopup(source) {
      const bankCCAccounts = this.getCredsBankCCAccounts(source.credentialsId);
      if (bankCCAccounts?.length) {
        this.openBankCCWarningPopup(source, bankCCAccounts);
      } else {
        this.openOBKConnectionFlow(source);
      }
    },
    openBankCCWarningPopup(source, bankCCAccounts) {
      this.openModal({
        component: BankCCWarningPopup,
        props: {
          source,
          bankCCAccounts,
          openOBKConnectionFlow: () => this.openOBKConnectionFlow(source),
        },
      });
    },
    openOBKConnectionFlow(source) {
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: source.sourceName,
          closedBankingCredentialsId: source.credentialsId,
          preventLoadingCredentialsCheck: true,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.new-credentials-list {
  @include responsive-padding;
  width: 100%;
  padding-top: 25px;

  .obk-disabled-source {
    .obk-disabled-source-content {
      display: flex;
      flex-direction: row;
      padding-bottom: 25px;

      .icon {
        margin: 1px 0 0 4px;
        .image {
          height: 27px;
        }
      }

      .text {
        line-height: 18px;
        font-family: SimplerPro;
        font-size: 12px;
        color: $riseup_gray_2;
        margin-right: 15px;

        .bold {
          font-weight: bold;
        }
      }
    }
  }

  .accounts-separator, .account-wrapper {
    margin-bottom: 24px;
  }

  .account-wrapper {
    .unsupported-desktop-notice {
      margin-top: 15px;
      text-align: right;
      line-height: 20px;
      font-size: 14px;

      .bold {
        font-weight: bold;
      }
    }
  }

  .new-account-wrapper {
    margin: 40px 0;
  }
}
</style>
