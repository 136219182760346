<template>
  <scrape-retry-failure v-if="obkScrapeRetryFailed" :source-name="displayName" :close-action="closeAction" />
  <first-scrape-failure v-else-if="obkScrapeFailed" :source-name="displayName" :close-action="closeAction" />
  <consent-creation-failure v-else :source-name="displayName" :close-action="closeAction" />
</template>

<script>
import FirstScrapeFailure
  from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/FirstScrapeFailure';
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  CONSENT_CREATION_FAILURE,
  OBK_SCRAPE_FAILED,
} from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/consts';
import ScrapeRetryFailure
  from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/ScrapeRetryFailure';
import ConsentCreationFailure
  from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/ConsentCreationFailure';

export default {
  name: 'OBKConnectionFailure',
  components: {
    ConsentCreationFailure,
    FirstScrapeFailure,
    ScrapeRetryFailure,
  },
  props: {
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('onboardingState', ['error', 'pageParams']),
    ...mapGetters('onboardingState', ['displayName']),
    obkScrapeFailed() {
      return this.error === OBK_SCRAPE_FAILED;
    },
    obkScrapeRetryFailed() {
      return this.obkScrapeFailed && this.pageParams?.scrapeTriesCount > 1;
    },
    consentCreationFailure() {
      return this.error === CONSENT_CREATION_FAILURE;
    },
  },
  methods: {
    ...mapMutations('onboardingState', ['setError']),
    closeAction() {
      this.setError(undefined);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
