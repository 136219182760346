<template>
  <card class="meitav-dash-request-card">
    <template v-slot:header>
      <div class="request-header">
        <div class="meitav-logo">
          <img src="@/assets/3rd-party-logos/meitav.svg"/>
        </div>
        <div class="status-badge">
          <img src="@/assets/icons/pending.svg">
        </div>
      </div>
    </template>
    <template v-slot:default>
      <div class="request-details">
        <div class="request-details-header">
          <div class="name ri-bold-body">{{ name }}</div>
          <div class="ri-large-headline">קופת גמל להשקעה</div>
        </div>
        <fund-detail icon="credit-card" :text="`הוראת קבע של ${formattedMonthlyDeposit} ₪`"></fund-detail>
        <fund-detail icon="event" text="כל 15 לחודש"></fund-detail>
      </div>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';
import _ from 'lodash';
import money from '@/utils/money';
import FundDetail from './FundDetail';

export default {
  name: 'MeitavDashRequestCard',
  components: {
    Card: BaseUI.Card,
    FundDetail,
  },
  props: {
    monthlyDeposit: {
      type: Number,
      required: true,
    },
    memberId: {
      type: String,
      required: true,
    },
    mine: {
      type: Boolean,
      required: true,
    },

  },
  computed: {
    ...mapState('session', ['members']),
    formattedMonthlyDeposit() {
      return money.formatAmount(this.monthlyDeposit);
    },
    name() {
      const member = _.find(this.members, m => m._id === this.memberId);
      return `${member.firstNamePiiValue} ${member.lastNamePiiValue}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.meitav-dash-request-card {
  margin-bottom: 25px;
  background-color: $riseup_white;
  color: $riseup_black;

  .name {
    color: $riseup_blue;
  }

  .request-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 0;
  }

  .request-details-header {
    margin-bottom: 20px;
  }

  .request-details {
    margin-bottom: 30px;
  }
}

</style>
