<template>
  <div class="open-kgl-cta" @click="openKGLClicked">
    <round-add-button class="icon"/>
    <div class="text">לפתיחת קופת גמל להשקעה ב-5 דק׳!</div>
  </div>
</template>

<script>
import OpenKglFlowAction
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/OpenKglFlowAction';
import RoundAddButton from '@/base-components/RoundAddButton';
import Segment from '@/Segment';

export default {
  name: 'OpenKglFlowCta',
  components: { RoundAddButton },
  mixins: [OpenKglFlowAction],
  methods: {
    openKGLClicked() {
      Segment.trackUserInteraction('MySavings_StartSaveKGL');
      this.openKGLFlow();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
.open-kgl-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px;
  border: dashed 1px $riseup-blue;
  border-radius: 8px;
  background-color: $riseup_white;
  cursor: pointer;

  .icon {
    margin-bottom: 20px;
  }

  .text {
    font-size: 16px;
    font-weight: bold;
    color: $riseup-blue;
  }
}

</style>
