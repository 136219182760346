<template>
  <information-page button-title="איך התזרים שלי מחושב?" @click="next" img-src="/images/onboarding/ob-cf-info.png"
                    :button-loading="loading">
    <template v-slot:title>
      <div class="flex-column">
        <div>תגידו ביי ביי לעו״ש</div>
        <div>והיי לתזרים החודשי</div>
      </div>
    </template>
    <template v-slot:content>
      <div>
        מהיום יש לך דרך חדשה להסתכל על הכסף שלך, התזרים החודשי! התזרים שלך נבנה כל חודש ומציג לך מספר אחד פשוט שעוזר לקבל החלטות
      </div>
    </template>
  </information-page>
</template>

<script>

import { mapActions } from 'vuex';
import Segment from '@/Segment';
import InformationPage from './components/InformationPage';

export default {
  components: {
    InformationPage,
  },
  name: 'CashflowIntroInformation',
  data() {
    return { loading: false };
  },
  created() {
    Segment.trackUserGot('infocashflowintroentered', { newBrand: true });
    Segment.trackUserGot('infocashflowintroentered_Marketing', { newBrand: true });
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    async next() {
      this.loading = true;
      Segment.trackUserInteraction('infocashflowintronextclicked', { newBrand: true });
      Segment.trackUserInteraction('infocashflowintronextclicked_Marketing', { newBrand: true });
      await this.onboardingPageSubmit();
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
}
</style>
