<template>
  <popup :close-action="close">
    <template v-slot:content>
      <div>
        <div>
          <lottie-player
            src="/animations/thanks.json"
            :autoplay="true"
            :preserveAspectRatio="'xMinYMax'"/>
        </div>
        <div class="text">
          <div>
            ביום ראשון הקרוב תקבלו וואטסאפ עם הנחיות נוספות.
          </div>
          <div class="upcoming">
            בינתיים כדאי לשריין את שלושת ימי שלישי הקרובים לפעילות הזוגית שהכנו.
          </div>
        </div>
        <riseup-button title="ניפגש"
                       size="slim"
                       :action="close" />
      </div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'MoneyMoonJoinModal',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot('MoneyMoonJoinPopup_Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    close() {
      Segment.trackUserGot('MoneyMoonJoinPopup_Closed');
      this.closeModal();
      router.push({ path: '/sr', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  text-align: center;
  padding: 30px 0px;
  font-size: 18px;
  line-height: 22px;
}

.upcoming {
  padding-top: 20px;
}
</style>
