<template>
  <div class="container">
    <div class="full-screen">
      <lottie-player src="/animations/new-brand-cashflow-loader.json"
                     :autoplay="true" :loop="true"
                     preserveAspectRatio="xMinYMid slice" />
    </div>
    <div class="full-screen flex-column flex-1 justify-content-center padding-right-xl ri-large-headline">
      <div class="ri-alte">אנחנו בונים</div>
      <div class="ri-alte">לך תזרים</div>
      <img src="/images/onboarding/preparing-cashflow.svg"/>
    </div>
  </div>
</template>

<script>
import DDLogs from '@/DDLogs';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Segment from '@/Segment';

const ONBOARDING_STATE_REFRESH_INTERVAL = 10;
const MAX_NUMBER_OF_POLLING = 6;
export default {
  name: 'PreparingCashflow',
  async created() {
    Segment.trackUserGot('ProgressStep3Entered');
    Segment.trackUserGot('CashflowLoaderEntered');
    Segment.trackUserGotToPixel('Activated_Marketing', {}, {
      firstName: this.activeMemberFirstName,
      lastName: this.activeMemberLastName,
      phoneNumber: this.activeMemberPhoneNumber,
      email: this.activeMemberEmail,
    });
    this.setLoadingState(true);
    this.startPolling();
  },
  data() {
    return {
      pollingInterval: null,
      pollingCounter: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapState('session', ['activeMemberFirstName', 'activeMemberLastName', 'activeMemberPhoneNumber', 'activeMemberEmail']),
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
  },
  methods: {
    ...mapActions('onboardingState', ['fetchOnboardingStateIfFinishedLoading']),
    ...mapMutations('onboardingState', ['setLoadingState']),

    startPolling() {
      this.pollingInterval = setInterval(async () => {
        await this.fetchOnboardingStateIfFinishedLoading();
        this.pollingCounter += 1;
        if (this.pollingCounter === MAX_NUMBER_OF_POLLING) {
          DDLogs.log('Stuck in PreparingCashflow');
        }
      }, ONBOARDING_STATE_REFRESH_INTERVAL * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.container {
  height: 100%;
  position: relative;
  color: $riseup_dark_green;
  background-color: $riseup_beige;

  .full-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 900;
  }

  img {
    height: 24px;
    align-self: flex-start;
  }
}

</style>
