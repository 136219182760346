<template>
  <div class="simple-image-page">
    <sticky-title :title="pageLabel" v-if="pageLabel"/>
    <div class="header-banner"><img :src="imgSrc"/></div>
    <div class="content">
      <div class="title-container">
        <div v-if="titleLabel" class="r-typo-headline-label title">{{ titleLabel }}</div>
        <div class="r-typo-headline title">{{ title }}</div>
      </div>
      <div class="text">
        <div v-if="text">
          {{ text }}
        </div>
        <slot name="textSlot"></slot>
      </div>
      <riseup-button v-if="buttonProps"
                     class="cta-button"
                     v-bind="buttonProps"
                     size="normal"
                     :bold="true"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import StickyTitle from '@/base-components/StickyTitle';

export default {
  name: 'SimpleImagePage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    StickyTitle,
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    pageLabel: {
      type: String,
      default: '',
      required: false,
    },
    titleLabel: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    buttonProps: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/mixins';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.simple-image-page {
  background-color: $riseup_white;
  margin-bottom: 32px;

  .header-banner {
    margin-bottom: 30px;

    img {
      max-width: 100%;
    }
  }

  .title-container {
    .title {
      margin-bottom: 10px;
    }
  }

  .content {
    width: 100%;
    @include responsive-padding;

    .text {
      line-height: 1.5;
      margin-bottom: 50px;
    }
  }

  .cta-button {
    width: 100%;
  }
}
</style>
