<template>
  <information-page button-title="איך המידע שלי מאובטח?" @click="next" img-src="/images/onboarding/ob-forward-looking.png"
                    :button-loading="loading">
    <template v-slot:title>
      בניגוד לעו״ש שמסתכל אחורה, התזרים שלך מסתכל קדימה
    </template>
    <template v-slot:content>
      <div>
        על בסיס חודשי העבר, התזרים יודע לצפות כבר בתחילת החודש כמה כסף יכנס,
        כמה כסף יצא וכמה נשאר לך להוציא. אפשר בקלות לתכנן ולהגשים חלומות ויעדים!
      </div>
    </template>
  </information-page>
</template>

<script>

import { mapActions } from 'vuex';
import Segment from '@/Segment';
import InformationPage from './components/InformationPage';

export default {
  components: {
    InformationPage,
  },
  name: 'ForwardLookingInformation',
  data() {
    return { loading: false };
  },
  async created() {
    Segment.trackUserGot('infoforwardlookingentered', { newBrand: true });
    Segment.trackUserGot('infoforwardlookingentered_Marketing', { newBrand: true });
  },
  methods: {
    ...mapActions('onboardingState', ['forwardLookingInformationSubmit']),
    async next() {
      this.loading = true;
      Segment.trackUserInteraction('infoforwardlookingnextclicked', { newBrand: true });
      Segment.trackUserInteraction('infoforwardlookingnextclicked_Marketing', { newBrand: true });
      await this.forwardLookingInformationSubmit();
      this.loading = false;
    },
  },
};
</script>
