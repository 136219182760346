<template>
  <div v-on="$listeners" class="open-link-button">
    <img :src="require('@/assets/open-link-button.svg')" width="60px" height="auto">
  </div>
</template>

<script>
export default {
  name: 'OpenLinkButton',
};
</script>

<style scoped lang="scss">
.open-link-button {
  cursor: pointer;
}
</style>
