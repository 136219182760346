<template>
  <div class="balance-page">
    <div class="title"> מצב העו״ש</div>
    <div class="balance-page-content">
      <callout v-if="shouldDisplayTooltip"
               iconPath="icons/heart.svg"
               class="tooltip" title="טיפ מאיתנו">
        <template v-slot:text>
          <div v-if="oshAndCashflowState === OshCashflowState.NegativeBalancePositiveCashflow">
            אמנם העו”ש במינוס אבל <b>התזרים החודשי חיובי,</b> זה רק אומר שאנחנו בדרך הנכונה!
          </div>
          <div v-else-if="oshAndCashflowState === OshCashflowState.NegativeBalanceNegativeCashflow">
            כדי להגיע לתזרים חיובי מומלץ להציב יעד חודשי לאחת הקטגוריות ולהתחיל לצמצם בהוצאות!
          </div>
          <div v-else-if="oshAndCashflowState === OshCashflowState.PositiveBalanceNegativeCashflow">
            {{ positiveBalanceNegativeCashflowTooltipText }}
          </div>
          <u v-if="!activeChallenge" @click="tooltipLinkClicked()" class="tooltip-link">{{tooltipLinkText}}</u>
        </template>
      </callout>

      <div v-if="hasBalance">
        <page-section-header title-text="יתרת העו״ש" color="green" icon="v-outline"/>
        <div class="cards-container">
          <base-balance-page-card v-for="data of balances" :key="data.accountNumberPiiValue"
                                  :amount="data.balance"
                                  :account="data.accountNumberPiiValue"
                                  :source="data.source"
                                  :last-updated="data.lastUpdated"
                                  :credentials-name="data.credentialsName"/>
        </div>
      </div>
      <div class='credit-card-debt' v-if="hasCreditCardDebt">
        <page-section-header title-text="חיובי האשראי" color="coral" icon="credit-card-outline"/>
        <div class="credit-card-disclaimer">סה״כ חיובים עתידיים: {{totalCreditCardsDebtAmount}}</div>
        <div class="cards-container">
          <base-balance-page-card v-for="data of cardDebts" :key="data.accountNumberPiiValue"
                                  :amount="data.amount"
                                  :account="`${data.accountNumberPiiValue} ****`"
                                  :source="data.source"
                                  :last-updated="data.lastUpdated"
                                  :credentials-name="data.name"/>
        </div>
      </div>
    </div>
    <div class="disclaimer-background">
      <div class="disclaimer-content">
        <div class="icon"><img :src="require('@/assets/icons/notice-gray.svg')"/></div>
        <div class="text"> בחלק מהמקרים, הסכומים שמופיעים כאן לא זהים לאלה באתרי הבנק וחברות האשראי.
          <span class="bold"> המערכת שלנו מחשבת רק תנועות שאושרו, ולא כוללת תנועות לא סופיות.</span></div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import DDLogs from '@/DDLogs';
import cashflowHistory from '@/store/utilities/cashflow-history';
import moneyUtils from '@/utils/money';
import PageSectionHeader from '../../../../../base-components/PageSectionHeader.vue';
import Segment from '../../../../../Segment';
import BaseBalancePageCard from './BaseBalancePageCard.vue';
import PositiveCashflowNegativeBalancePopup from './popups/PositiveCashflowNegativeBalancePopup';
import ChallengeFlow from '../cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeFlow';

export default {
  name: 'BalancePage',
  components: {
    BaseBalancePageCard,
    PageSectionHeader,
    Callout: BaseUI.Callout,
  },
  async created() {
    this.startContentLoading(this.$options.name);
    await this.initBalance();

    if (_.isEmpty(this.cashflow)) {
      const budgetDate = cashflowHistory.getCurrentBudgetDate();
      await this.initBudgets({ budgetDate });
    }

    if (this.activeChallenge === null) {
      await this.fetchActiveChallenge();
    }

    Segment.trackUserGot('BalancePageEntered', {
      oshAndCashflowState: this.oshAndCashflowState,
    });

    this.doneContentLoading(this.$options.name);
  },
  data() {
    return {
      OshCashflowState: {
        NegativeBalancePositiveCashflow: 'NegativeBalancePositiveCashflow',
        NegativeBalanceNegativeCashflow: 'NegativeBalanceNegativeCashflow',
        PositiveBalanceNegativeCashflow: 'PositiveBalanceNegativeCashflow',
        PositiveBalancePositiveCashflow: 'PositiveBalancePositiveCashflow',
      },
    };
  },
  computed: {
    ...mapState('balance', ['balances', 'cardDebts']),
    ...mapState('challenge', ['activeChallenge']),
    ...mapState('cashflowView', ['cashflow']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapGetters('createTrackingCategory', ['currentDefaultTrackingCategories']),
    ...mapGetters('balance', ['hasBalance', 'hasCreditCardDebt', 'totalCreditCardsDebt']),
    isCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
    isTotalBalancePositive() {
      return _.sumBy(this.balances, 'balance') >= 0;
    },
    shouldDisplayTooltip() {
      return this.oshAndCashflowState
          && this.oshAndCashflowState !== this.OshCashflowState.PositiveBalancePositiveCashflow
          && (this.isCashflowPositive
          || (!this.isCashflowPositive && this.hasDefaultTrackingCategories)); // since we wont have a category for the challenge from the tip
    },
    hasDefaultTrackingCategories() {
      return this.currentDefaultTrackingCategories?.length > 0;
    },
    oshAndCashflowState() {
      if (this.isTotalBalancePositive && this.isCashflowPositive) {
        return this.OshCashflowState.PositiveBalancePositiveCashflow;
      }
      if (this.isTotalBalancePositive && !this.isCashflowPositive) {
        return this.OshCashflowState.PositiveBalanceNegativeCashflow;
      }
      if (!this.isTotalBalancePositive && this.isCashflowPositive) {
        return this.OshCashflowState.NegativeBalancePositiveCashflow;
      }
      if (!this.isTotalBalancePositive && !this.isCashflowPositive) {
        return this.OshCashflowState.NegativeBalanceNegativeCashflow;
      }
      return undefined;
    },
    totalCreditCardsDebtAmount() {
      return `₪${moneyUtils.formatAmountWithFraction(Math.abs(this.totalCreditCardsDebt))}`;
    },
    positiveBalanceNegativeCashflowTooltipText() {
      return this.activeChallenge
        ? `הגדרת לעצמך יעד חודשי בקטגוריית ${this.activeChallenge}, זה צעד משמעותי בדרך לתזרים חיובי!`
        : 'כדי להגיע לתזרים חיובי מומלץ להציב יעד חודשי לאחת הקטגוריות ולהתחיל לצמצם בהוצאות!';
    },
    tooltipLinkText() {
      return this.oshAndCashflowState === this.OshCashflowState.NegativeBalanceNegativeCashflow ? 'להציב יעד חודשי' : ' מה כדאי לעשות?';
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('balance', ['initBalance']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('challenge', ['fetchActiveChallenge']),
    tooltipLinkClicked() {
      Segment.trackUserGot('BalancePage_TooltipLink_Clicked', {
        oshAndCashflowState: this.oshAndCashflowState,
      });
      switch (this.oshAndCashflowState) {
      case this.OshCashflowState.NegativeBalancePositiveCashflow:
        this.openModal({ component: PositiveCashflowNegativeBalancePopup });
        break;
      case this.OshCashflowState.NegativeBalanceNegativeCashflow:
      case this.OshCashflowState.PositiveBalanceNegativeCashflow:
        this.openModal({
          component: ChallengeFlow,
          props: { sourceComponent: this.$options.name },
        });
        break;
      default:
        DDLogs.error('Unhandled cashflow-balance case', {
          isTotalBalancePositive: this.isTotalBalancePositive,
          isCashflowPositive: this.isCashflowPositive,
          oshAndCashflowState: this.oshAndCashflowState,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.balance-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .title {
    margin: 24px 34px 20px 24px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px
  }

  .tooltip {
    margin-bottom: 30px;

    .tooltip-link {
      color: $riseup_blue;
      font-weight: 550;
      cursor: pointer;
    }
  }

  .disclaimer-background {
    background-color: rgba($riseup_gray_disabled, 0.5);

    .disclaimer-content {
      @include responsive-padding;
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-bottom: 10px;

      .icon {
        margin: 1px 0 0 4px;
      }

      .text {
        line-height: 16px;
        font-family: SimplerPro;
        font-size: 12px;
        color: $riseup_gray_2;

        .bold {
          font-weight: bold;
        }
      }
    }
  }

  .balance-page-content {
    @include responsive-padding;
    flex-grow: 1;

    .credit-card-debt {
      margin-top: 50px;

      .cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .credit-card-disclaimer {
        margin-bottom: 20px;
      }
    }
  }
}

</style>
