<template>
  <div class="fund-detail">
    <div class="icon"><img :src="require(`@/assets/icons/${icon}.svg`)"></div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'FundDetail',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.fund-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;

  .text {
    margin-right: 10px;
  }
}
</style>
