<template>
  <card class="base-balance-page-card">
    <template v-slot:default>
      <div class="balance-content">
        <div class="last-updated">מעודכן ל-{{ formattedDate }}</div>
        <div class="source">{{ sourceDisplayName }}</div>
        <div class="credentials-name">{{ credentialsName }}</div>
        <div class="amount" :class="{ negative: amount < 0 }">{{ formattedAmount }} ₪</div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="account">{{ account }}</div>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import moneyUtils from '../../../../../utils/money';

export default {
  name: 'BaseBalancePageCard',
  components: {
    Card: BaseUI.Card,
  },
  props: {
    account: { type: String, required: true },
    source: { type: String, required: true },
    amount: { type: Number, required: true },
    credentialsName: { type: String, required: true },
    lastUpdated: { type: String, required: true },
  },
  computed: {
    sourceDetails() {
      return getSourceConfigurationBySourceName(this.source);
    },
    formattedDate() {
      return moment(this.lastUpdated).format('DD.MM.YY, HH:mm');
    },
    sourceDisplayName() {
      return this.sourceDetails.displayName;
    },
    formattedAmount() {
      return moneyUtils.signedFormatAmountWithFraction(this.amount);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .base-balance-page-card {
    margin: 0 0 16px;
    background-color: $riseup_white;

    .balance-content{
      padding: 24px 0;
    }

    .last-updated {
      font-size: 12px;
      color: $riseup_gray_2;
    }

    .source {
      margin-top: 8px;
      font-size: 22px;
      font-weight: bold;
    }

    .account {
      margin-top: 6px;
      font-size: 16px;
      padding: 24px 0;
    }

    .credentials-name {
      margin-top: 3px;
    }

    .amount {
      margin-top: 28px;
      text-align: right;
      font-size: 46px;
      font-weight: bold;
      color: $riseup_green;

      &.negative {
        color: $riseup_danger_red;
      }
    }
  }
</style>
