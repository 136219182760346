<template>
  <div class="credentials-settings">
    <sticky-title title="חשבונות מחוברים"/>
    <div class="credentials-list-container">
      <credentials-list v-if="!partialScrapeEnabled"></credentials-list>
      <new-credentials-list v-else></new-credentials-list>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import StickyTitle from '@/base-components/StickyTitle';
import router from '@/router';
import NewCredentialsList from '@/base-components/credentials/credentials-list/NewCredentialsList';
import CredentialsList from '../../../../../base-components/credentials/credentials-list/CredentialsList.vue';
import Segment from '../../../../../Segment';

export default {
  name: 'CredentialsSettings',

  components: {
    NewCredentialsList,
    StickyTitle,
    CredentialsList,
  },
  async created() {
    this.startContentLoading(this.$options.name);
    // We should perform this in the router for  all cashflow pages, we start an experiment here
    if (this.isOnboarding) {
      router.push({ path: '/sr' });
      return;
    }
    await this.fetchCredentials();
    this.doneContentLoading(this.$options.name);
    Segment.trackUserGot('CredentialsSettingsEntered', {});
  },
  computed: {
    ...mapGetters('onboardingState', ['isOnboarding']),
    ...mapGetters('session', ['householdName']),
    ...mapGetters('featureFlags', ['partialScrapeEnabled']),
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('credentials', ['fetchCredentials']),
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.credentials-settings {
  background-color: $riseup_beige;
  flex: 1;

  .credentials-list-container {
    display: flex;
    justify-content: center;
  }
}
</style>
