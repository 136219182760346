<template>
  <connected-credentials-card
                              class="invalid-credentials-card"
                              :source="source"
                              :source-type="source.type"
                              :name="source.name"
                              :sourceName="source.sourceName"
                              :status="source.status"
                              :accounts="source.accounts"
                              :enable-menu="true">
    <template v-slot:buttons>
      <riseup-button v-if="migrationFromPasswords"
                    class="update-account-btn-migration"
                     title="לחידוש החיבור"
                     size="slim"
                     :action="handleCredentialsUpdateClick"/>
      <riseup-button v-else
        class="update-account-btn"
        subtitle="לעדכון פרטי חיבור החשבון"
        size="slim"
        :bold="true"
        :action="handleCredentialsUpdateClick"/>
    </template>
  </connected-credentials-card>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import ConnectedCredentialsCard from '../../onboarding/ConnectedCredentialsCard';

export default {
  name: 'InvalidCrednetialsCard',
  components: {
    ConnectedCredentialsCard,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    source: { type: Object, required: true },
    migrationFromPasswords: { type: Boolean, require: false, default: false },
  },
  methods: {
    handleCredentialsUpdateClick() {
      this.$emit('update-credentials');
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid-credentials-card {
  .update-account-btn {
    margin-top: 40px;
  }

  .update-account-btn-migration {
    margin-top: 30px;
  }
}
</style>
