<template>
  <sticky-cta-page :pageBackgroundColor="ctaBackgroundColor" :ctaProps="ctaProps" @click="$emit('click')">
    <div class="information-page flex-column flex-1" :style="{ backgroundColor }">
      <div class="information-page-title ri-large-headline ri-alte">
        <slot name="title"/>
      </div>
      <div class="information-page-content ri-large-body flex-column flex-1 padding-horizontal-ml">
        <slot name="content"/>
        <div class="flex-1 flex-column justify-content-center">
          <img :src="imgSrc">
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>

import BaseUI from '@riseupil/base-ui';

export default {
  name: 'InformationPage',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: BaseUI.Colors.riseupLightYellow,
    },
    imgSrc: {
      type: String,
      required: true,
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ctaBackgroundColor() {
      return this.backgroundColor;
    },
    ctaProps() {
      return {
        buttonProps: {
          title: this.buttonTitle,
          loading: this.buttonLoading,
        },
        backgroundColor: this.backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.information-page {
  .information-page-title {
    color: $riseup_dark_green;
    padding: $ri-spacing-ml $ri-spacing-ml $ri-spacing-sm $ri-spacing-ml;
  }

  .information-page-content {
    color: $riseup_dark_green;
  }
}

</style>
