<template>
  <div class="retry-button">
    <riseup-button
      class="retry-button"
      :subtitle=subtitle
      size="slim"
      :variant=buttonVariant
      :loading=processingInProgress
      :bold="true"
      :action="tryNow"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import CustomersApi from '@/api/CustomersApi';

export default {
  name: 'PartiallyAuthorizedRetryScrapeButton',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      processingInProgress: false,
      processingTimeout: false,
      timeout: null,
    };
  },
  created() {
    Segment.trackUserGot('OBKWaitingForPartnerEntered', { });
  },
  computed: {
    subtitle() {
      if (this.processingTimeout) {
        return 'עדיין לא, נסו שוב מאוחר יותר';
      }
      return 'אפשר להתקדם, יש אישור בבנק';
    },
    buttonVariant() {
      if (this.processingTimeout) {
        return 'grayed';
      }
      return 'primary';
    },
  },
  methods: {
    async tryNow() {
      Segment.trackUserInteraction('OBKWaitingForPartner_RecheckAgain');
      this.processingTimeout = false;
      this.processingInProgress = true;
      this.timeout = setTimeout(this.giveUpWaiting, 30000);
      await CustomersApi.customerTriggeredScrape().catch(() => {});
    },
    giveUpWaiting() {
      this.processingTimeout = true;
      this.processingInProgress = false;
    },
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style lang="scss">
.retry-button {
  margin-top: 30px;
}
</style>
