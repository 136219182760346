<template>
  <component :is="onboardingComponent"/>
</template>

<script>
import PromotionDuringOnboardingPopup from '@/base-components/onboarding/PromotionDuringOnboardingPopup';
import DDLogs from '@/DDLogs';
import { mapActions, mapState, mapGetters } from 'vuex';
import router from '@/router';
import StateRouter from '@/pages/responsive-pages/authenticated/routing/StateRouter';
import CustomersApi from '@/api/CustomersApi';
import MetricApi from '@/api/MetricsApi';
import _ from 'lodash';
import consts from '../../../../constants/onboarding-state';
import BotiIntro from './steps/BotiIntro.vue';
import ExpenseInvestigationOnboarding from './steps/expense-investigation/ExpenseInvestigationOnboarding.vue';
import SecurityInformation from './steps/SecurityInformation';
import PrepareCredentials from './steps/bank-connection/PrepareCredentials';
import BankCredentialsOnboarding from './steps/bank-connection/BankCredentialsOnboarding';
import IncomeExclusionOnboarding from './steps/income-exclusion/HighIncomeExclusionOnboarding.vue';
import CreditCardCredentialsOnboarding from './steps/credit-card-connection/CreditCardCredentialsOnboarding';
import CashflowIntroInformation from './steps/CashflowIntroInformation.vue';
import ForwardLookingInformation from './steps/ForwardLookingInformation.vue';
import obkPartiallyAuthorized from './ObkPartiallyAuthorized';
import IncomeInvestigationOnboarding from './steps/income-investigation/IncomeInvestigationOnboarding';
import OshBalanceInformation from './steps/OshBalanceInformation';
import PreparingCashflow from './steps/PreparingCashflow';
import TimelineIntro from './steps/timeline-intro/TimelineIntro';
import SecurityInformationV2 from './steps/security-info-v2/SecurityInformationV2.vue';
import PrepareCredentialsV2 from './steps/bank-connection/PrepareCredentialsV2';

export default {
  name: 'OnboardingFlow',
  components: {
    PromotionDuringOnboardingPopup,
  },
  async mounted() {
    this.verifyPermissions();
  },
  async created() {
    this.addTrustedSiteScript();
    await this.handleOnboardingState();
    if (this.$route.query && this.$route.query.returning) {
      await this.redeemBenefitForReturningCustomer();
      await this.addRefererAndUtmParams();
    }
  },
  computed: {
    ...mapGetters('subscriptionState', ['isDormant']),
    ...mapState('session', ['customerId']),
    ...mapState('onboardingState', ['pageName', 'isLoading', 'error', 'pageParams']),
    ...mapState('websocket', ['socketMessage']),
    ...mapState('benefits', ['benefitId']),
    pageToComponent() {
      return {
        [consts.ONBOARDING_PAGE.BOTI_INTRO]: BotiIntro,
        [consts.ONBOARDING_PAGE.CASHFLOW_INTRO_INFORMATION]: CashflowIntroInformation,
        [consts.ONBOARDING_PAGE.FORWARD_LOOKING_INFORMATION]: ForwardLookingInformation,
        [consts.ONBOARDING_PAGE.SECURITY_INFORMATION]: SecurityInformation,
        [consts.ONBOARDING_PAGE.PREPARE_CREDENTIALS]: PrepareCredentials,
        [consts.ONBOARDING_PAGE.BANK_DETAILS]: BankCredentialsOnboarding,
        [consts.ONBOARDING_PAGE.OBK_PARTIALLY_AUTHORIZED]: obkPartiallyAuthorized,
        [consts.ONBOARDING_PAGE.OSH_BALANCE_INFORMATION]: OshBalanceInformation,
        [consts.ONBOARDING_PAGE.INCOME_INVESTIGATION]: IncomeInvestigationOnboarding,
        [consts.ONBOARDING_PAGE.INCOME_EXCLUSION]: IncomeExclusionOnboarding,
        [consts.ONBOARDING_PAGE.CREDIT_CARD_DETAILS]: CreditCardCredentialsOnboarding,
        [consts.ONBOARDING_PAGE.EXPENSE_INVESTIGATION]: ExpenseInvestigationOnboarding,
        [consts.ONBOARDING_PAGE.PREPARING_CASHFLOW]: PreparingCashflow,
        [consts.ONBOARDING_PAGE.CASHFLOW]: StateRouter, // This is just in case the user gets here after he finished OB

        // New pages for shorter ob experiment
        [consts.ONBOARDING_PAGE.TIMELINE_INTRO]: TimelineIntro,
        [consts.ONBOARDING_PAGE.SECURITY_INFORMATION_V2]: SecurityInformationV2,
        [consts.ONBOARDING_PAGE.PREPARE_CREDENTIALS_V2]: PrepareCredentialsV2,
      };
    },
    onboardingComponent() {
      return this.pageToComponent[this.pageName];
    },
    utmParams() {
      const utmParams = _.pickBy(this.$route.query, (value, key) => (key.startsWith('utm_') && value));
      // If a page has duplicate utm params, it will send an array.
      // Since this is not expected we just take the first one
      return _.mapValues(utmParams, val => (_.isArray(val) ? val[0] : val));
    },
  },
  methods: {
    ...mapActions('promotions', ['fetchPromotion']),
    ...mapActions('onboardingState', ['fetchOnboardingStateIfFinishedLoading', 'handleOnboardingState']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('benefits', ['createBenefit']),
    addTrustedSiteScript() {
      const tag = document.createElement('script');
      tag.setAttribute('src', 'https://cdn.ywxi.net/js/1.js');
      document.head.appendChild(tag);
    },
    async redeemBenefitForReturningCustomer() {
      if (this.$route.query.promoCode) {
        const { promoCode } = this.$route.query;
        await this.fetchPromotion(promoCode);
        await this.createBenefit(promoCode);
        this.openModal({
          component: PromotionDuringOnboardingPopup,
        });
      }
    },
    async addRefererAndUtmParams() {
      const intake = {};
      if (!_.isEmpty(this.utmParams)) {
        intake.utmParams = this.utmParams;
      }
      if (this.$route.query.icrc) {
        const { icrc } = this.$route.query;
        intake.icrc = icrc;
      }
      if (!_.isEmpty(intake)) {
        await CustomersApi.updateCustomerIntake({ intake });
      }
    },
    verifyPermissions() {
      if (this.isDormant) {
        this.$router.push({ path: '/home' });
      }
    },
  },
  watch: {
    pageName(newValue) {
      MetricApi.sendCounter('onboarding.page.entered', { page: newValue });
    },
    async socketMessage(newValue, oldValue) {
      DDLogs.log(`Socket message changed from  ${oldValue} to ${newValue}, is loading = ${this.isLoading}`,
        {
          oldValue,
          newValue,
          loading: this.isLoading,
        });
      if (newValue !== oldValue) {
        if (this.isLoading) {
          await this.fetchOnboardingStateIfFinishedLoading();
          if (this.pageName === consts.ONBOARDING_PAGE.CASHFLOW) {
            router.push({ path: '/sr' });
          }
        }
      }
    },
  },
};
</script>
