<template>
    <riseup-button
       :loading="loading"
       :action="runAction"
       :disabled="disabled || isClicked"
       :subtitle="subtitle"
       :variant="variant"
       :size="size"
       :icon="icon"
       :title="title"
    />
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SingleClickButton',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      isClicked: false,
    };
  },
  props: {
    action: Function,
    title: String,
    subtitle: String,
    variant: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    icon: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    async runAction() {
      this.isClicked = true;
      await this.action();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
