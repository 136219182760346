<template>
  <div v-on="$listeners" class="round-add-button">
    <img src="/images/onboarding/icons/icon-add.svg" :width="width" height="auto">
  </div>
</template>

<script>
export default {
  name: 'RoundAddButton',
  props: {
    width: {
      type: String,
      default: '60px',
    },
  },
};
</script>

<style scoped lang="scss">
.round-add-button {
  cursor: pointer;
}
</style>
