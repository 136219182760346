<template>
  <sticky-cta-page :page-background-color="ctaProps.backgroundColor" :cta-props="ctaProps" @click="next">
    <div class="security-page flex-column flex-1" :style="{ backgroundColor }">
      <div class="security-info-title">
        <div class="ri-large-headline ri-alte margin-bottom-s">
          שומרים על המידע שלך
        </div>
        <div class="ri-large-body">
          עם טכנולוגיות אבטחת המידע מהמתקדמות בעולם, אפשר לחבר את רייזאפ בחיבור ישיר לצורך קריאת נתונים בלבד, ללא סיסמה ושם משתמש.
        </div>
        <div class="flex-column justify-content-center margin-vertical-ml">
          <img class="lock-image" src="/images/onboarding/ob-security-info-v2.png">
        </div>
      </div>
      <div class="security-info-content flex-column">
        <div>
          <div class="ri-large-headline margin-vertical-l margin-horizontal-ml">עשרות אלפי ישראלים וישראליות בוטחים בנו עם המידע שלהם</div>
          <slider :slides-data="testimonials" :gap="24" auto-width class="margin-bottom-xxl">
            <template v-slot:default="{ slideData }">
              <testimonial :image="slideData.image" :person-name="slideData.personName" :testimonial="slideData.testimonial"/>
            </template>
          </slider>
        </div>
        <div class="flex-column flex-1 align-center padding-bottom-xxl" v-for="(content, index) in securityBullets"
             :key="index">
          <colored-svg svgSrc="/images/shapes/half-circle-bottom.svg" :color="bulletIconColor" class="bullet-icon"/>
          <div class="ri-bold-body margin-bottom-xs">{{ content.line1 }}</div>
          <div class="ri-medium-headline">{{ content.line2 }}</div>
          <div class="ri-medium-headline">{{ content.line3 }}</div>
        </div>
        <div class="padding-top-xs">
          <img src="/images/onboarding/ob-security-trust-logos.svg" class="full-width">
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import Testimonial from './Testimonial.vue';

export default {
  name: 'SecurityInformationV2',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    ColoredSvg: BaseUI.ColoredSvg,
    Slider: BaseUI.Slider,
    Testimonial,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupBeige,
      ctaProps: {
        buttonProps: {
          loading: false,
          title: 'לחיבור החשבונות',
        },
        backgroundColor: BaseUI.Colors.riseupBeige,
      },
      bulletIconColor: BaseUI.Colors.riseupGreen,
      testimonials: [
        {
          key: 'yossi',
          image: 'yossi.png',
          personName: 'יוסי קיצברג',
          testimonial: 'מעבר לזה האבטחה ברייזאפ כ״כ טובה שאני לא חושש, השירות מדויק וזה פשוט שווה. מעולם לא הרגשתי כ״כ בשליטה על החשבון שלי!',
        },
        {
          key: 'ira',
          image: 'ira.png',
          personName: 'אירה אולנובסקי',
          testimonial: 'אני כבר שנה איתם אחרי שבדקתי את תקנון האבטחה שלהם בשבע עניים. תבדקו ותצטרפו!',
        },
        {
          key: 'eric',
          image: 'eric.png',
          personName: 'אריק פלהיימר',
          testimonial: 'מיד הבנתי שיש פה עסק עם אנשים רציניים שעובדים יום וליל כדי שהמידע יהיה שמור ומאובטח בצורה הטובה ביותר.',
        },
      ],
      securityBullets: [
        {
          line1: 'למערכת יש גישה לקריאת הנתונים בלבד',
          line2: 'לא נבצע שום פעולות',
          line3: 'בחשבון הבנק שלך',
        },
        {
          line1: 'פועלים במסגרת הבנקאות הפתוחה',
          line2: 'חיבור חשבון פשוט ללא',
          line3: 'צורך בסיסמה',
        },
        {
          line1: 'אנחנו מאגר מידע רשום',
          line2: 'בפיקוח הרשות לניירות ערך',
          line3: 'והרשות להגנת הפרטיות',
        },
        {
          line1: 'הכסף שלך מוגן בכל מצב',
          line2: 'יש לך איתנו ביטוח אחריות',
          line3: 'מקצועית של חברת AIG',
        },
      ],
    };
  },
  async created() {
    Segment.trackUserGot('SecurityPageV2_Entered');
    Segment.trackUserGot('SecurityPageV2_Entered_Marketing');
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    async next() {
      this.ctaProps.buttonProps.loading = true;
      Segment.trackUserInteraction('SecurityPageV2_NextClicked');
      Segment.trackUserInteraction('SecurityPageV2_NextClicked_Marketing');
      await this.onboardingPageSubmit();
      this.ctaProps.buttonProps.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.security-info-title {
  color: $riseup_dark_green;
  padding: $ri-spacing-ml $ri-spacing-ml $ri-spacing-sm $ri-spacing-ml;
  background-color: $riseup_light_blue;

  .lock-image {
    max-height: 250px;
    object-fit: scale-down;
  }
}

.security-info-content {
  color: $riseup_dark_green;
}

.bullet-icon {
  height: 30px;
  width: 30px;
  margin-bottom: $ri-spacing-sm;
}
</style>
