<template>
  <information-page v-if="!contentLoading" button-title="לחישוב התזרים שלי" @click="next" :backgroundColor="backgroundColor"
                    img-src="/images/onboarding/ob-balance-information.png" :button-loading="loading">
    <template v-slot:title>
      <div>
        <div>עם התזרים החודשי קל יותר</div>
        <div>להתחיל לשים כסף בצד</div>
      </div>
    </template>
    <template v-slot:content>
      <div>
        <div v-if="balance" class="margin-bottom-ml">
          <div>אז כרגע מצב העו״ש שלך הוא ₪{{balance}}</div>
        </div>
        <div class="margin-bottom-ml">מיד נבנה לך תזרים ראשון ונציג לך בדיוק בדיוק כמה אפשר עוד להוציא עד סוף החודש</div>
        <div>
          <span>החודש הסתיים בתזרים חיובי?</span>
          <span class="ri-large-bold-body">שמת כסף בצד, הגדלת את היתרה בבנק והתקרבת צעד נוסף לחלומות והיעדים שלך!</span>
        </div>
      </div>
    </template>
  </information-page>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import InformationPage from './components/InformationPage';

export default {
  components: {
    InformationPage,
  },
  name: 'OshBalanceInformationOld',
  data() {
    return {
      loading: false,
      backgroundColor: BaseUI.Colors.riseupWhite,
    };
  },
  async created() {
    this.startContentLoading(this.$options.name);
    await this.initBalance();
    Segment.trackUserGot('OBbalancestateenteredOld', { newBrand: true });
    Segment.trackUserGot('OBbalancestateenteredOld_Marketing', { newBrand: true });
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapState('balance', ['balances']),
    ...mapGetters('contentLoader', ['contentLoading']),
    balance() {
      if (this.balances.length === 1) {
        return this.balances[0].balance.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      }
      return null;
    },
  },
  methods: {
    ...mapActions('onboardingState', ['oshBalanceInformationSubmit']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('balance', ['initBalance']),
    async next() {
      this.loading = true;
      Segment.trackUserInteraction('OBbalancestatenextclicked', { newBrand: true });
      Segment.trackUserInteraction('OBbalancestatenextclicked_Marketing', { newBrand: true });
      await this.oshBalanceInformationSubmit();
      this.loading = false;
    },
  },
};
</script>
