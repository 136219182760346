import { render, staticRenderFns } from "./BankCredentialsOnboarding.vue?vue&type=template&id=17d87a08&scoped=true&"
import script from "./BankCredentialsOnboarding.vue?vue&type=script&lang=js&"
export * from "./BankCredentialsOnboarding.vue?vue&type=script&lang=js&"
import style0 from "./BankCredentialsOnboarding.vue?vue&type=style&index=0&id=17d87a08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d87a08",
  null
  
)

export default component.exports