<script>
export default {
  name: 'OpenKglFlowAction',
  methods: {
    openKGLFlow() {
      window.open('https://saving.riseup.co.il', '_blank');
    },
  },
};
</script>
