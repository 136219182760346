<template>
<div class="item">
  <div class="timeline">
    <div :class="['line', { checked }]" />
    <div class="image">
      <colored-svg class="flower" :color="timelineColor" svg-src="/images/shapes/eight-leaves-flower.svg" />
      <icon :icon-name="timelineIcon" :color="iconColor" weight="bold" variant="rounded" size="18px" fill />
    </div>
  </div>
  <div :class="['texts', { checked }]">
    <div class="ri-bold-body">{{ title }}</div>
    <div class="ri-body">{{ description }}</div>
  </div>
</div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TimelineItem',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
    Icon: BaseUI.Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
    },
  },
  computed: {
    timelineColor() {
      return this.checked ? BaseUI.Colors.riseupLightGreen : BaseUI.Colors.riseupBlue;
    },
    iconColor() {
      return this.checked ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupLightBlue;
    },
    timelineIcon() {
      return this.checked ? 'check' : this.iconName;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'timeline texts';
  grid-gap: $ri-spacing-ml;
  align-items: center;
  justify-items: stretch;

  // Add padding to every direct child to keep the texts and the timeline
  // with the same padding, so there will be no gaps in the timeline lines
  & > * {
    padding: 0 0 $ri-spacing-l 0;
  }

  .texts {
    grid-area: texts;
    display: flex;
    flex-direction: column;
    gap: $ri_spacing_xxs;
    color: $riseup_dark_green;

    &.checked {
      color: $riseup_green;
    }
  }

  .timeline {
    position: relative;
    grid-area: timeline;
    align-self: self-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .line {
      position: absolute;
      height: 100%;
      // This is to have the line connect between current flower center and previous flower center
      top: -75%;
      width: 3px;
      background-color: $riseup_light_blue;

      &.checked {
        background-color: $riseup_light_green;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      .flower {
        width: 43px;
        height: 43px;
      }

      .icon {
        position: absolute;
        z-index: 2;
      }
    }
  }
}
</style>
