<template>
  <card class="meitav-dash-fund-card">
    <template v-slot:header>
      <div class="fund-header">
        <div class="meitav-logo">
          <img src="@/assets/3rd-party-logos/meitav.svg"/>
        </div>
        <div class="status-badge">
          <img src="@/assets/icons/verified.svg">
        </div>
      </div>
    </template>
    <template v-slot:default>
      <div class="fund-details">
        <div class="fund-details-header">
          <div class="name ri-bold-body">{{ name }}</div>
          <div class="ri-large-headline">קופת גמל להשקעה</div>
        </div>
        <fund-detail icon="credit-card" :text="`הוראת קבע של ${formattedMonthlyDeposit} ₪`"></fund-detail>
        <fund-detail icon="event" text="כל 15 בחודש"></fund-detail>
        <fund-detail icon="assignment-ind" :text="`חשבון ${fundNumber}`"></fund-detail>
      </div>
    </template>
    <template v-slot:footer v-if="mine">
      <div class="actions">
        <div class="top-actions">
          <action label="להגדיל סכום">
            <round-add-button @click="openUpdateDepositFlow"/>
          </action>
          <action label="להקטין סכום">
            <round-remove-button @click="openReduceDepositFlow"/>
          </action>
          <action label="לאזור האישי">
            <open-link-button @click="openPersonalAreaInMeitavDash"/>
          </action>
        </div>
        <riseup-button title="להפקיד סכום חד-פעמי" @click="openOneTimeDepositFlow"></riseup-button>
      </div>
    </template>

  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import money from '@/utils/money';
import RoundAddButton from '@/base-components/RoundAddButton';
import RoundRemoveButton from '@/base-components/RoundRemoveButton';
import OpenLinkButton
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/OpenLinkButton';
import { mapActions, mapMutations, mapState } from 'vuex';
import DynamicDepositFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DynamicDepositFlow';
import Segment from '@/Segment';
import FundDetail from './FundDetail';
import Action from './Action';

export default {
  name: 'MeitavDashFundCard',
  components: {
    Action,
    OpenLinkButton,
    RoundRemoveButton,
    RoundAddButton,
    Card: BaseUI.Card,
    RiseupButton: BaseUI.RiseupButton,
    FundDetail,
  },
  props: {
    monthlyDeposit: {
      type: Number,
      required: true,
    },
    fundNumber: {
      type: String,
      required: true,
    },
    memberId: {
      type: String,
      required: true,
    },
    mine: {
      type: Boolean,
      required: true,
    },

  },
  computed: {
    ...mapState('session', ['members']),
    formattedMonthlyDeposit() {
      return money.formatAmount(this.monthlyDeposit);
    },
    name() {
      const member = _.find(this.members, m => m._id === this.memberId);
      return `${member.firstNamePiiValue} ${member.lastNamePiiValue}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('dynamicSavings', ['setOpportunisticDeposit', 'setMonthlyDeposit', 'setReduceMonthlyDeposit', 'setProposedSavingsAmount']),
    openOneTimeDepositFlow() {
      Segment.trackUserInteraction('MySavings_KGLAddDeposit');
      this.setOpportunisticDeposit();
      this.openDynamicDepositFlow();
    },
    openUpdateDepositFlow() {
      Segment.trackUserInteraction('MySavings_KGLIncrease');
      this.setMonthlyDeposit();
      this.setProposedSavingsAmount(100);
      this.openDynamicDepositFlow();
    },
    openReduceDepositFlow() {
      Segment.trackUserInteraction('MySavings_KGLDecrease');
      this.setReduceMonthlyDeposit();
      this.openDynamicDepositFlow();
    },
    openDynamicDepositFlow() {
      this.openModal({
        component: DynamicDepositFlow,
        popupAlignment: 'full-screen',
      });
    },
    openPersonalAreaInMeitavDash() {
      Segment.trackUserInteraction('MySavings_KGLGoToMeitav');
      window.open('https://customers.meitav.co.il/v2/login/loginAmit', '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.meitav-dash-fund-card {
  margin-bottom: 25px;
  background-color: $riseup_white;
  color: $riseup_black;

  .name {
    color: $riseup_blue;
  }

  .fund-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 0;
  }

  .fund-details-header {
    margin-bottom: 20px;
  }

  .fund-details {
    margin-bottom: 30px;
  }

  .actions {
    padding: 25px 0;

    .top-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 25px;

    }
  }
}

</style>
