<template>
  <upsell-page title="קופת גמל להשקעה"
               cta-text="לפתיחת קופה ב-5 דקות!"
               headline="למה לחסוך איתנו?"
               img-src="/images/saving-zone/no-funds-header-image.svg"
               :pageBackgroundColor="backgroundColor"
               :text-color="textColor"
               @click="openKGLClicked">
    אפשר לפתוח חיסכון דרכנו ולהעביר אליו בקלות סכומים משתנים ישירות מהתזרים.
    בלי טופסולוגיה. בלי כאב ראש. בכמה דקות.
  </upsell-page>
</template>

<script>
import OpenKglFlowAction
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/OpenKglFlowAction';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'EmptyState',
  mixins: [OpenKglFlowAction],
  components: {
    UpsellPage: BaseUI.Pages.UpsellPage,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupGreen,
      textColor: BaseUI.Colors.riseupWhite,
    };
  },
  methods: {
    openKGLClicked() {
      Segment.trackUserInteraction('MySavings_VpStartSaveKGL');
      this.openKGLFlow();
    },
  },
};
</script>
