<template>
  <div class="transaction-check-list">
    <div v-for="(transaction, index) in transactionList" :key="index" class="transaction-row">
      <transaction-check-list-item :transaction="transaction" :toggleCheck="toggleCheck"/>
      <div class="separator-wrapper">
        <separator/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import TransactionCheckListItem from '../TransactionCheckListItem.vue';

export default {
  name: 'TransactionCheckList',
  components: {
    Separator: BaseUI.Separator,
    TransactionCheckListItem,
  },
  props: {
    transactionList: {
      type: Array,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.transaction-check-list {
  background-color: $riseup_gray_-1;

  .transaction-row {

    .transaction-check-list-item {
      @include responsive-padding;
    }

    &:last-child {
    .separator-wrapper {
        padding: 0;
      }
    }
    .separator-wrapper {
      padding: 0 15px;
    }
  }
}
</style>
