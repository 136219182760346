<template>
  <div class="subscription-cancelled">
    <div class="information">
      <div class="body">
        <div class="title">
          <div class="information-title">
            המינוי שלך הופסק.
          </div>
          <div class="information-subtitle"><span :class="{ bold: partOneBold }">{{ infoSubtitlePartOne }}</span>{{ infoSubtitlePartTwo }}
<div class="bold">אבל אנחנו עדין פה בשבילך.</div></div>
        </div>
        <div class="description">
          <div v-for="bullet in bullets" :key="bullet.icon" class="bullet">
            <div class="bullet-icon">
              <colored-svg :color="bullet.iconColor" :svg-src="`/images/shapes/${bullet.icon}.svg`" class="icon"/>
            </div>
            <div class="bullet-text">
              <span class="bullet-title">
                {{ bullet.title }}
              </span>
              {{ bullet.text }}
            </div>
          </div>
        </div>
        <div class="footer">
          <single-click-button :title="'הבנתי'" :loading="isLoading" :action="goToCashflow" :size="'slim'"/>
          <div class="churn-button" v-on:click="goToChurn">אני רוצה למחוק את החשבון שלי</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
import Segment from '@/Segment';
import ChurnPopup from '@/pages/responsive-pages/authenticated/churn/ChurnPopup.vue';
import BaseUI from '@riseupil/base-ui';
import SingleClickButton from '../../../../base-components/SingleClickButton';

export default {
  async created() {
    await this.initSubscriptionState();
    if (!this.isSubscriptionCancelled) {
      await router.push({ path: '/sr' });
    } else {
      Segment.trackUserInteraction('CancelSubscriptionSummary_Shown');
    }
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    SingleClickButton,
    coloredSvg: BaseUI.ColoredSvg,
  },
  name: 'CancelSubscriptionSummary',
  computed: {
    ...mapGetters('subscriptionState', ['dormantAfterCanceled', 'hasAcceptedMoneyBackOffer']),
    isSubscriptionCancelled() {
      return this.dormantAfterCanceled;
    },
    bullets() {
      return [
        {
          title: 'נמשיך לשלוח',
          text: 'תוכן, וובינרים, טיפים ותובנות מהצוות ומהקהילה, ועוד הפתעות... ',
          icon: 'chubby',
          iconColor: BaseUI.Colors.riseupYellow,
        },
        {
          title: 'השירות שלנו גדל ומתפתח,',
          text: 'נעדכן אותך על דברים מרגשים שיגיעו בעתיד',
          icon: 'cross',
          iconColor: BaseUI.Colors.riseupGreen,
        },
        {
          title: 'אנחנו שומרים על כל ההגדרות וההעדפות שלך',
          text: '  כדי שיהיה אפשר לחזור לשירות בקלות',
          icon: 'ten-pointed-star',
          iconColor: BaseUI.Colors.riseupBlue,
        },
      ];
    },
    partOneBold() {
      return !this.hasAcceptedMoneyBackOffer;
    },
    infoSubtitlePartOne() {
      return 'התשלום עבור המינוי של רייזאפ הופסק והתזרים לא יחושב מרגע זה.';
    },
    infoSubtitlePartTwo() {
      return this.hasAcceptedMoneyBackOffer
        ? ''
        : `
הזיכוי על החלק היחסי מהחודש ששולם יתקבל בימים הקרובים לחשבון האשראי.`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('subscriptionState', ['initSubscriptionState']),
    async goToCashflow() {
      Segment.trackUserInteraction('SubscriptionCancelled_GoToCashflow');
      this.isLoading = true;
      window.location = '/home';
    },
    goToChurn() {
      Segment.trackUserInteraction('SubscriptionCancelled_ChurnButtonClicked');
      this.openModal({
        component: ChurnPopup,
        props: {
          skipSurvey: true,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

@keyframes spin {
  from {
    transform: rotate(-25deg);
  }
  to {
    transform: rotate(335deg);
  }
}

.subscription-cancelled {
  height: 100%;

  .information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .body {
      color: $riseup_black;
      position: relative;
      flex-direction: column;
      flex-grow: 1;
      padding: 26px;
      padding-bottom: 0px;
      text-align: right;
      background: white;
      background: linear-gradient(180deg, $riseup_danger_red 0%, $riseup_danger_red 250px, white 250px);

      .title {
        padding: 20px 0px 10px;
        white-space: pre-line;
        color: white;

        .information-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
        }

        .information-subtitle {
          font-size: 16px;
          line-height: 24px;
          white-space: normal;

          .bold {
            font-weight: bold;
          }
        }
      }

      .description {
        padding: 6px 0 0;
        line-height: 1.3;
        color: white;

        .bullet {
          width: 100%;
          margin-bottom: 15px;
          padding: 15px 11px;
          border: 1px solid #DDDDDD;
          border-radius: 15px;
          background: white;
          display: flex;
          flex-direction: row;
          color: black;

          .bullet-icon {
            padding: 10px 7px 0;

            .icon {
              width: 42px;
              height: 42px;
            }
          }

          .bullet-text {
            flex-grow: 1;
            padding: 5px 10px 0 5px;
            font-size: 16px;

            .bullet-title {
              font-weight: bold;
            }
          }
        }
      }

      .bold {
        font-weight: bold;
      }

    }

    lottie-player {
      height: auto;
    }

    .footer {
      justify-content: center;
      background-color: $riseup_white;
      padding: 10px 0;

      .churn-button {
        margin: 25px auto 0;
        display: block;
        text-align: center;
        font-size: 18px;
        color: $riseup_blue;
        cursor: pointer;
      }
    }
  }
}

</style>
