<template>
  <div class="no-creditcards-continue-popup">
    <popup :buttons="buttons" :close-action="popupClosed">
      <template v-slot:title>
        <img class="logo-image" src="/images/onboarding/big-icon-alert.svg">
        <div class="popup-title">לא חיברת אף חשבון אשראי,
          רוצה להמשיך בכל זאת?</div>
      </template>
      <template v-slot:content>
        <div class="description">
          בלי אף חשבון מחובר המערכת תוכל לבנות לך תמונת מעקב חודשית חלקית בלבד. להמשיך בכל זאת?
        </div>
      </template>
    </popup>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '../../Segment';

export default {
  name: 'NoCreditCardsContinuePopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: {
    nextPage: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('NoCreditCardsContinuePopupEntered');
  },
  computed: {
    buttons() {
      return [
        {
          title: 'חזרה לחיבור החשבונות',
          action: this.returnToCreditCardsSelected,
          size: 'slim',
        },
        {
          title: 'אני רוצה להמשיך בכל זאת',
          action: this.continueWithoutSelected,
          variant: 'secondary',
          size: 'slim',
        },
      ];
    },
  },
  methods: {
    returnToCreditCardsSelected() {
      Segment.trackUserInteraction('ReturnToCreditCardsClicked');
      this.closeModal();
    },
    continueWithoutSelected() {
      Segment.trackUserInteraction('ContinueWithoutCreditCardsClicked');
      this.closeModal();
      this.nextPage();
    },
    popupClosed() {
      Segment.trackUserInteraction('NoCreditCardsContinuePopupClosed');
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .no-creditcards-continue-popup {
    .action-icon {
      margin-bottom: 15px;
    }

    .popup-title {
      font-family: SimplerPro;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 18px;
      text-align: center;
      padding: 0 18px 0 18px;
      white-space: pre-line;
    }

    .logo-image {
      width: 100%;
      text-align: center;
      height: 110px;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .description {
      margin-bottom: 35px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px 0 10px;
    }
  }

</style>
