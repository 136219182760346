<template>
  <div class="input-container">
    <div class="input-group">
      <input
        :type="'string'"
        :value="fieldValue"
        :disabled="true"
        autocapitalize="none"/>
      <label class="field-name">{{ fieldText }}</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SecretInputField',
  props: {
    fieldText: {
      type: String,
    },
    fieldValue: {
      type: String,
    },
    onEdit: {
      type: Function,
      required: false,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.input-container {
  margin-bottom: 20px;

  .input-group {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 56px;

    .icon {
      position: absolute;
      top: 17px;
      left: 20px;
    }

    .top-left-label {
      position: absolute;
      top: -13px;
      right: -2px;
      width: 100%;
      text-align: left;
      font-size: 11px;
      color: $riseup_gray_2;
    }

    > input {
      display: block;
      grid-column: 1/2;
      grid-row: 1/2;
      font-size: 20px;
      width: 100%;
      height: 56px;
      padding: 10px 10px 10px 5px;
      border: 1px solid $riseup_gray_inputs;
      border-radius: 3px;

      &:disabled {
        color: $riseup_gray_2;
        border: 1px solid $riseup_gray_2;
        background-color: $riseup_gray_disabled;
      }

      &:disabled ~ .field-name {
        position: relative;
        top: -75px;
        font-size: 12px;
        color: $riseup_gray_2;
      }
    }
  }
}
</style>
