<template>
  <div class="obk-partially-authorized">
    <div>
      <riseup-header/>
    </div>
    <div class="content">
      <div class="upper-content">
        <img class="wait-icon" src="/images/onboarding/icons/pending-joint.svg">
        <div class="title">החשבון חובר בהצלחה אבל ממתין לאישור השותף או השותפה לחשבון</div>
        <div class="help-section">
          נשלחה לשותפ.ה הודעת sms עם לינק לאישור. אחרי האישור, חיבור הבנק יושלם.
        </div>
      </div>
      <partially-authorized-retry-scrape
        :ready-to-scrape-title="'הבנק כבר קיבל אישור מהשותפ.ה לחשבון?'"
        :ready-to-scrape-button-text="'אפשר לנסות ולהתקדם כבר עכשיו'"
        :ready-to-scrape-timout-title="''"
        :ready-to-scrape-timout-button-text="'עדיין לא, אפשר לנסות שוב מאוחר יותר'"
      />
      <div class="separator">
        <div class="line"></div>
        <div class="text">או</div>
        <div class="line"></div>
      </div>
      <div class="direct">
        <div>לא רוצה להמתין לאישור השותפ.ה?</div>
        <div>
          <a v-on:click="switchToDirect" href="#">
            לחיבור החשבון עם שם משתמש וסיסמה
          </a>
        </div>
      </div>
      <riseup-button @click="openBankingPartiallyAuthorisedContinue" title="אפשר להמשיך" variant="secondary"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapMutations, mapState } from 'vuex';
import PartiallyAuthorizedRetryScrape
  from '@/base-components/credentials/credentials-card/open-banking/PartiallyAuthorizedRetryScrape';
import Segment from '../../../../Segment';
import RiseupHeader from '../../../../base-components/app-header/RiseupHeader.vue';

export default {
  name: 'ObkPartiallyAuthorized',
  components: {
    RiseupHeader,
    RiseupButton: BaseUI.RiseupButton,
    PartiallyAuthorizedRetryScrape,
  },
  async created() {
    this.setLoadingState(true);
    Segment.trackUserGot('ObkPartiallyAuthorized_Shown', { query: this.$route.query });
  },
  computed: {
    ...mapState('onboardingState', ['pageName']),
  },
  methods: {
    ...mapMutations('onboardingState', ['setPageName', 'setPageParams', 'setLoadingState']),
    ...mapActions('onboardingState', ['fetchOnboardingState', 'openBankingSwitchToCredentials', 'openBankingPartiallyAuthorisedContinue']),
    async switchToDirect() {
      Segment.trackUserInteraction('ObkSwitchToCreds');
      await this.openBankingSwitchToCredentials();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.obk-partially-authorized {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  .content {
    @include white-page-frame;
    @include responsive-padding;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 25px;

    .wait-icon {
      margin: 60px 0 30px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      color: $riseup_black;
      margin-bottom: 15px;
      line-height: 1.3;
    }
    .separator {
      margin: 25px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .line {
        height: 1px;
        width: 100%;
        background-color: $riseup_gray_1;
      }

      .text {
        padding: 0 23px;
        flex-grow: 0;
      }
    }

    .direct {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 24px;
    }

    .help-section {
      margin-bottom: 120px;
      font-size: 16px;
      line-height: 1.38;
      color: $riseup_black;

      .link {
        text-decoration: underline;
        color: $riseup_blue;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>
