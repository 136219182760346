<template>
  <image-header-page v-if="hasFunds"
                     :imageHeaderProps="imageHeaderProps">
    <template v-slot:overlayContent>
      <component :is="getComponent(mainFund)"
                 v-bind="getFundProps(mainFund)"
                 :key="mainFund.details.fundNumber"/>
    </template>
    <div class="saving-zone-container">
      <component v-for="fund in otherFunds"
                 :is="getComponent(fund)"
                 v-bind="getFundProps(fund)"
                 :key="fund.details.fundNumber"/>
      <open-kgl-flow-cta class="open-kgl-flow-cta" v-if="!memberHasFund"/>
    </div>
  </image-header-page>
  <empty-state v-else/>
</template>

<script>
import _ from 'lodash';
import MeitavDashFundCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/MeitavDashFundCard';
import MeitavDashRequestCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/MeitavDashRequestCard';
import { mapGetters, mapState, mapActions } from 'vuex';
import EmptyState from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/EmptyState';
import StickyTitle from '@/base-components/StickyTitle';
import OpenKglFlowCta
  from '@/pages/responsive-pages/authenticated/navigation-pages/saving-zone/components/OpenKglFlowCta';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SavingZone',
  components: {
    OpenKglFlowCta,
    StickyTitle,
    EmptyState,
    MeitavDashFundCard,
    MeitavDashRequestCard,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
  },
  data() {
    return {
      imageHeaderProps: {
        bottomSeparator: false,
        title: 'חסכונות\nעם רייזאפ',
        imgSrc: '/images/saving-zone/dashboard-page.svg',
        textColor: BaseUI.Colors.riseupDarkGreen,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_GREEN,
      },
    };
  },
  async created() {
    this.startContentLoading(this.$options.name);
    await this.initSavingZone();
    this.doneContentLoading(this.$options.name);
    Segment.trackUserInteraction('MySavings_Entered');
    this.setAppHeaderProps({
      backgroundColorVariant: this.hasFunds ? BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_GREEN : BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
      textColor: this.hasFunds ? BaseUI.Colors.riseupDarkGreen : BaseUI.Colors.riseupWhite,
      logoColor: this.hasFunds ? BaseUI.Colors.riseupDarkGreen : BaseUI.Colors.riseupWhite,
      sticky: false,
    });
  },
  computed: {
    ...mapState('savingZone', ['funds']),
    ...mapGetters('savingZone', ['hasFunds']),
    mainFund() {
      return this.sortedFunds[0];
    },
    otherFunds() {
      return _.slice(this.sortedFunds, 1);
    },
    sortedFunds() {
      return _.sortBy(this.funds, fund => (fund.my ? 0 : 1));
    },
    memberHasFund() {
      return _.some(this.funds, fund => fund.my);
    },
    headerBgColor() {
      return this.hasFunds ? BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_GREEN : BaseUI.LayoutConsts.COLOR_VARIANT.GREEN;
    },
    headerTextColor() {
      return this.hasFunds ? BaseUI.Colors.riseupDarkGreen : BaseUI.Colors.riseupWhite;
    },
  },
  methods: {
    ...mapActions('savingZone', ['initSavingZone']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    getComponent(item) {
      if (item.details._type === 'fund') {
        return MeitavDashFundCard;
      }
      if (item.details._type === 'request') {
        return MeitavDashRequestCard;
      }
      DDLogs.error('Unexpected item in saving zone', { item });
      return undefined;
    },
    getFundProps(item) {
      if (item.details._type === 'fund') {
        return {
          monthlyDeposit: item.details.monthlyDepositSum,
          fundNumber: item.details.fundNumber,
          memberId: item.memberId,
          mine: item.my,
        };
      }
      if (item.details._type === 'request') {
        return {
          monthlyDeposit: item.details.monthlyDepositSum,
          memberId: item.memberId,
          mine: item.my,
        };
      }

      return {};
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/scss/mixins';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.header {
  color: $riseup_dark_green;

  .text {
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 70%;
  }
}

.saving-zone-container {
  width: 100%;
  @include responsive-padding;
  padding-top: 25px;
  margin-bottom: 25px;
}

</style>
