<template>
  <div>
    <div class="accumulation-break-down ri-body">
      <div>סה״כ נצברו בחמשת החודשים האחרונים
        <span class="ri-font-weight-bold">{{ last5CashflowstotalAccumulatedSumFormatted }}</span>
        <span v-if="(last5CashflowstotalAccumulatedSum < 0)">-</span>
        ₪</div>
      <div>פירוט הצבירה:</div>
      <div>+ מהתזרימים (ללא העברות לחסכון): {{ last5CashflowsTotalCashflowsAccumulation }}</div>
      <div>+ העברות חסכון לצבירה מהתזרים: {{ last5CashflowsTotalSavingAccumulation }}</div>
      <div>+ הכנסות לא תזרימיות שהתווספו לצבירה: {{ incomeExclusionsIntoIntoAccumulation }}</div>
      <div>- הוצאות לא תזרימיות מתוך הצבירה: {{ last5CashflowsWithdrawnFromAccumulation }}</div>
    </div>
    <div class="graph">
      <bar-graph :v-if="barValues" :with-sign='true' :graph-height="graphConfig.graphHeight" :bar-values="barValues"/>
    </div>
    <div>
      <check-box v-model="showCashflowBalance" :initially-checked="showCashflowBalance">
        <template v-slot:title>
          <div class="margin-right-sm">
            הצג צבירה מהתזרים
          </div>
        </template>
      </check-box>
    </div>
    <div>
      <check-box v-model="showSavings" :initially-checked="showSavings">
        <template v-slot:title>
          <div class="margin-right-sm">
            הצג חסכון
          </div>
        </template>
      </check-box>
    </div>
    <div>
      <check-box v-model="showExpenseExclusionsThatAreNotSavings" :initially-checked="showExpenseExclusionsThatAreNotSavings">
        <template v-slot:title>
          <div class="margin-right-sm">
            הצג הוצאות מוחרגות
          </div>
        </template>
      </check-box>
    </div>
    <div>
      <check-box v-model="showIncomeExclusionsThatAreNotSavings" :initially-checked="showIncomeExclusionsThatAreNotSavings">
        <template v-slot:title>
          <div class="margin-right-sm">
            הצג הכנסות מוחרגות
          </div>
        </template>
      </check-box>
    </div>
    <expandable-transaction-check-list
                      title="טרנזאקציות שניתן להחריג מהצבירה"
                      :toggleCheck="markAsExcludedFromPool"
                      :transactionList="transactionsThatCanBeExcludedFromPool" />
    <separator class="margin-top-l margin-bottom-l"/>
    <div class="padding-bottom-xxl">
      <div class="ri-medium-headline margin-bottom-s">צבירה שנתית:</div>
      <div class="ri-body">
        <div>סה״כ צבירה בשנה האחרונה
          <span class="ri-font-weight-bold">{{ yearlyTotalAccumulatedSumFormatted }}</span>
          <span v-if="(yearlyTotalAccumulatedSum < 0)">-</span>
          ₪</div>
        <div>פירוט הצבירה:</div>
        <div>+ מהתזרימים (ללא העברות לחסכון): {{ yearlyTotalCashflowsAccumulation }}</div>
        <div>+ העברות חסכון לצבירה מהתזרים: {{ yearlyTotalSavingAccumulation }}</div>
        <div>+ הכנסות לא תזרימיות שהתווספו לצבירה: {{ yearlyIncomeExclusionsIntoIntoAccumulation }}</div>
        <div>- הוצאות לא תזרימיות מתוך הצבירה: {{ yearlyWithdrawnFromAccumulation }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import ExpandableTransactionCheckList from '@/base-components/onboarding/ExpandableTransactionCheckList';

export default {
  name: 'RiseupPoolRaw',
  components: {
    BarGraph: BaseUI.BarGraph,
    CheckBox: BaseUI.CheckBox,
    Separator: BaseUI.Separator,
    ExpandableTransactionCheckList,
  },
  props: {
    riseupPool: {
      type: Array,
      required: true,
    },
    savingTransactionIdsFromHamster: {
      type: Array,
      required: true,
    },
    refreshRiseupPool: {
      type: Function,
      required: true,
    },
    excludeTransactionsFromPool: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      graphConfig: {
        graphHeight: 300,
      },
      showCashflowBalance: true,
      showSavings: true,
      showExpenseExclusionsThatAreNotSavings: true,
      showIncomeExclusionsThatAreNotSavings: true,
      transactionsThatCanBeExcludedFromPool: [],
    };
  },
  async created() {
    this.transactionsThatCanBeExcludedFromPool = this.exludedFromRiseupPool(this.riseupPool);
  },
  computed: {
    last5months() {
      return this.lastNCashflowsBreakDown(5, this.transactionsThatCanBeExcludedFromPool);
    },
    lastYear() {
      return this.lastNCashflowsBreakDown(12, this.transactionsThatCanBeExcludedFromPool);
    },
    barValues() {
      return this.last5months.map(breakDown => this.barValue(breakDown));
    },
    last5CashflowstotalAccumulatedSumFormatted() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.last5months, breakDown => this.totalAccumulation(breakDown)), 0,
      );
    },
    last5CashflowstotalAccumulatedSum() {
      return _.sumBy(this.last5months, breakDown => this.totalAccumulation(breakDown));
    },
    last5CashflowsTotalCashflowsAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.showCashflowBalance && this.last5months, breakDown => breakDown.cashflowBalance), 0,
        0,
      );
    },
    last5CashflowsTotalSavingAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.showSavings && this.last5months,
          accBreakDown => accBreakDown.savingEnvelopesActualsSum + accBreakDown.oneTimeSavingActualSum),
        0,
      );
    },
    last5CashflowsWithdrawnFromAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.showExpenseExclusionsThatAreNotSavings && this.last5months,
          accBreakDown => accBreakDown.expenseExclusionsThatAreNotSavings),
        0,
      );
    },
    incomeExclusionsIntoIntoAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.showIncomeExclusionsThatAreNotSavings && this.last5months,
          accBreakDown => accBreakDown.incomeExclusionsThatAreNotSavings),
        0,
      );
    },
    yearlyTotalAccumulatedSumFormatted() {
      return moneyUtils
        .formatAmountWithFraction(_.sumBy(this.lastYear, breakDown => this.totalAccumulation(breakDown, true)), 0);
    },
    yearlyTotalAccumulatedSum() {
      return _.sumBy(this.lastYear, breakDown => this.totalAccumulation(breakDown, true));
    },
    yearlyTotalCashflowsAccumulation() {
      return moneyUtils
        .formatAmountWithFraction(_.sumBy(this.lastYear, breakDown => breakDown.cashflowBalance), 0);
    },
    yearlyTotalSavingAccumulation() {
      return moneyUtils
        .formatAmountWithFraction(_.sumBy(
          this.lastYear, accBreakDown => accBreakDown.savingEnvelopesActualsSum + accBreakDown.oneTimeSavingActualSum,
        ),
        0);
    },
    yearlyWithdrawnFromAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.lastYear, accBreakDown => accBreakDown.expenseExclusionsThatAreNotSavings), 0,
      );
    },
    yearlyIncomeExclusionsIntoIntoAccumulation() {
      return moneyUtils.formatAmountWithFraction(
        _.sumBy(this.lastYear, accBreakDown => accBreakDown.incomeExclusionsThatAreNotSavings), 0,
      );
    },
  },
  methods: {
    lastNCashflowsBreakDown(n) {
      const _formatMonthName = cashflowMonth => moment(cashflowMonth)
        .locale('he')
        .format('MMM');
      return _.chain(this.riseupPool)
        .map(pool => {
          return {
            ...pool,
            hebrewMonthName: _formatMonthName(pool.cashflowMonth),
          };
        })
        .sortBy(pool => pool.cashflowMonth)
        .take(n)
        .reverse()
        .value();
    },
    isExcluded(ex) {
      return !(ex.isPapa
        || (ex.excludeReason === 'zeroSum')
        || this.savingTransactionIdsFromHamster.find(id => id === ex.transactionId));
    },
    barValue(accBreakDown) {
      return {
        color: BaseUI.Colors.riseupBlue,
        value: this.totalAccumulation(accBreakDown, false),
        name: accBreakDown.hebrewMonthName,
      };
    },
    totalAccumulation(accBreakDown, showAllValues) {
      return ((showAllValues || this.showCashflowBalance) ? accBreakDown.cashflowBalance : 0)
           + ((showAllValues || this.showSavings) ? accBreakDown.savingEnvelopesActualsSum : 0)
           + ((showAllValues || this.showSavings) ? accBreakDown.oneTimeSavingActualSum : 0)
           + ((showAllValues || this.showExpenseExclusionsThatAreNotSavings) ? accBreakDown.expenseExclusionsThatAreNotSavings : 0)
           + ((showAllValues || this.showIncomeExclusionsThatAreNotSavings) ? accBreakDown.incomeExclusionsThatAreNotSavings : 0);
    },
    async markAsExcludedFromPool(transaction) {
      this.transactionsThatCanBeExcludedFromPool = this.transactionsThatCanBeExcludedFromPool.map(excludedTr => {
        if (transaction.transactionId === excludedTr.transactionId) {
          return { ...excludedTr, isChecked: !excludedTr.isChecked };
        }
        return excludedTr;
      });
      await this.excludeTransactionsFromPool(this.transactionsThatCanBeExcludedFromPool
        .filter(ex => !ex.isChecked).map(ex => ex.transactionId));
      await this.refreshRiseupPool();
    },
    exludedFromRiseupPool(riseupPool) {
      return _.chain(riseupPool).flatMap(rp => rp.excludedNonSavingsExpenses)
        .map(excludedTr => {
          return {
            ...excludedTr,
            isChecked: !excludedTr.excludedFromPool,
            amount: (excludedTr.incomeAmount ?? excludedTr.billingAmount),
          };
        }).value();
    },
  },
  watch: {
    transactionsThatCanBeExcludedFromPool: {
      handler() {
        this.refreshRiseupPool(this.transactionsThatCanBeExcludedFromPool
          .filter(excludedTr => excludedTr.isChecked));
      },
      deep: true,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

</style>
